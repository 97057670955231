import React from 'react';
import './chapter-seven.scss';

const chapterSeven = () => {
	return (
		<div className="chapterSeven">
			<div className='chapterSeven-background'/>
			<div className='chapterSeven-water'/>
			<div className='chapterSeven-clouds'/>
			<div className='chapterSeven-foreground chapterSeven-foreground--1'/>
			<div className='chapterSeven-foreground chapterSeven-foreground--2'/>
			<div className='chapterSeven-grass chapterSeven-grass--1'/>
			<div className='chapterSeven-grass chapterSeven-grass--2'/>
			<div className='chapterSeven-grass chapterSeven-grass--3'/>
		</div>
	);
};


export default chapterSeven;