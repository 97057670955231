import { useEffect } from 'react';
import firebaseInit from 'firebase-init';
import PopupController from 'components/popup/popup-controller';
import GameController from 'components/game/game-controller';
import './app.scss';
import appConfig from '../config/app.config';


const App = () => {

	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */

		/* Initialize firebase */
		firebaseInit();

		/* Component will ummount */
		// return () => {};
	});

	/**
	* Toggle fullscreen
	*/
	const handleFullscreen = () => {
		if ((appConfig.env !== 'production' && appConfig.env !== 'demo')) {return;}
		let elem = document.documentElement;
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.mozRequestFullScreen) { /* Firefox */
			elem.mozRequestFullScreen();
		} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) { /* IE/Edge */
			elem.msRequestFullscreen();
		}
	};



	return (
		<div id="app" className={'App'}>
			<PopupController>
				<GameController handleFullscreen={handleFullscreen}/>
			</PopupController>
		</div>
	);
};



export default App;
