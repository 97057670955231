const cookieUiTexts = {
	text: `Vi vil gerne registrere, hvor langt du er nået i spillet, så du kan spille videre, næste gang du logger ind. </br> Det gør vi med en cookie. Tryk 'Ja' for at acceptere denne cookie.`,	
	bigtext: `Vi vil gerne registrere, hvor langt du er nået i spillet, så du kan spille videre, næste gang du logger ind. Det gør vi med en cookie. </br> Cookien bliver gemt i et år. Vi indsamler ikke andre oplysninger, og vi deler ikke oplysningerne om, hvor langt du er nået i spillet med andre. Tryk på 'Ja' for at acceptere denne cookie. </br> Du kan altid trække dit ”ja” tilbage ved at trykke på den lille cookieknap i nederste venstre hjørne.`,
	consentButtons: {ok: 'Ja', cancel: 'Nej'},
	readMore: 'Læs mere.',
	popupText: 'For at starte et spil skal du accepterer brugen af cookies, ved at klikke på den lille cookieknap i venstre hjørne.',
};

const generalUiTexts = {
	part: 'Del',
	chapter: 'Kapitel',
	linkIntroduction: 'Introduktion',
	linkStory: 'Historien',
	linkResources: 'Materialer',
	linkAboutGame: 'Om spillet',
	materiallist: 'Materialeliste',
	before: 'Hjemme',
	during: 'Ude i naturen',
	after: 'Historien',
	badges: 'Mærker',
	noActivitiesSelected2: 'Du har ikke valgt nogen aktiviteter.',
	noActivitiesSelected: 'Du har ikke valgt nogen aktiviteter i denne del.',
	activityParts: {
		talkAbout: 'Snak om',
		illustrations: 'Tegninger',
		facts: 'Fakta',
		focusWords: 'Fokusord',
		materials: 'Materialer'
	},
	printTitle: 'Ask & Liv',
	wholestory: 'Hele historien',
	wholestorySubtitle: 'Ask & Liv flytter boplads', 
};

const popUpUiTexts = {
	activity: 'Aktivitet',
	story: 'Historie',
	readStory: 'Læs historie',
	openActivity: 'Åbn aktiviteten',
	addToPlan: 'Vælg',
	printActivity: 'Print denne aktivitet',
	printPlan: 'Print alle valgte aktiviteter',
	printStory: 'Print dette kapitel',
	printStories: 'Print hele historien',
	planEmail: 'Send valgte aktiviteter i en mail:',
	storyEmail: 'Send hele historien i en mail:',
	placeholderInput: 'Indtast e-mail',
	sendBtn: 'Send'
}

const emailUiTexts = {
	emailSent: 'Mailen blev sendt!',
	emailError: 'Noget gik galt. Tjek, om adressen er rigtig.',
};

const fetchUiTexts = {
	fetchError: 'Noget gik galt. Tjek, om du har forbindelse til internettet.'
}

export{
	cookieUiTexts,
	generalUiTexts,
	popUpUiTexts,
	emailUiTexts,
	fetchUiTexts
};