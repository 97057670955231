import React from 'react';
import PropTypes from 'prop-types';
import popupBadgesInfoText from 'data/pages/info-badges.md';
import Badges from './badges';

class BadgesController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			initialSelectedBadgeIds: []
		};
		
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		if (!this.props.badgesIntroSeen) {
			this.props.openPopup('infoPopup', null, popupBadgesInfoText, [], null, true);
			this.props.handleBadgesIntroSeen();
		}

		this.setState({initialSelectedBadgeIds: this.props.selectedBadgeIds});
	};

	/**
	 * User touched badge
	 */
	toggleBadgesSelector = (badgeId) => {
		if (this.props.selectedBadgeIds.indexOf(badgeId) >= 0) {
			/* Badge is selected, deselect it */
			this.deselectBadge(badgeId);
		} else {
			/* Badge is not selected, select it */
			this.props.toggleBadge(badgeId);
		}
	};


	/**
	 * Deselect badge
	 * @param {string} badgeId
	 */
	deselectBadge = (badgeId) => {
		this.props.toggleBadge(badgeId);

		let initialSelectedBadgeIds = this.state.initialSelectedBadgeIds;
		let badgeIndex = initialSelectedBadgeIds.indexOf(badgeId);
		if (badgeIndex >= 0) {initialSelectedBadgeIds.splice(badgeIndex, 1);}
		this.setState({initialSelectedBadgeIds});
	};

	/**
	 * Render component
	 */
	render = () => {
		return (
			<Badges
				prevPage = {this.props.prevPage}
				initialSelectedBadgeIds = {this.state.initialSelectedBadgeIds}
				selectedBadgeIds = {this.props.selectedBadgeIds}
				selectPage = {this.props.selectPage}
				toggleBadgesSelector = {this.toggleBadgesSelector}
				toggleBadges = {this.props.toggleBadges}
				openPopup = {this.props.openPopup}
			/>
		);
	};
}

BadgesController.defaultProps = {
	toggleBadges: null
};

BadgesController.propTypes = {
	badgesIntroSeen: PropTypes.bool.isRequired,
	prevPage: PropTypes.string.isRequired,
	selectedBadgeIds: PropTypes.array.isRequired,
	handleBadgesIntroSeen: PropTypes.func.isRequired,
	selectPage: PropTypes.func.isRequired,
	toggleBadge: PropTypes.func.isRequired,
	toggleBadges: PropTypes.func,
	openPopup: PropTypes.func.isRequired,
};

export default BadgesController;