import React from 'react';
import PropTypes from 'prop-types';
import {popUpUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './popup.scss';

const PopupInfo = (props) => {
	let {
		isLoading,
		isSendingEmail,
		showCloseBtn,
		showEmailForm,
		popupClass,
		popupTitle,
		popupText,
		popupBtns,		
		emailInputField,
		emailFeedback,
		handleButtonClick,
		handleTextInputChange,
		sendEmail,
		closePopup,
		printFeedback
	} = props;
	
	let className = 'Popup'; 
	if (popupClass) className += ' Popup--' + popupClass;
	return (
		<div className={className} onClick={(e) => {e.stopPropagation();}}>
			{showCloseBtn && <div className="Popup-closeBtn" onClick={() => {
				closePopup();
			}}></div>}
			<div className="Popup-body" onClick={(e) => {e.stopPropagation();}}>
				{popupTitle && <div className="Popup-title">{popupTitle}</div>}
				{popupText && <div className="Popup-text">{renderMarkdown(popupText)}</div>}
				{popupBtns && <div className={'Popup-buttons Popup-buttons--' + popupBtns.length}>
					{popupBtns.map((btn, index) => {
						return (
							<Button
								key={index}
								type="button"
								class={btn.class ? btn.class : 'popup'}
								text={btn.text}
								loading={isLoading && btn.showLoadingIcon}
								onClick={() => {handleButtonClick(btn);}}
							/>
						);
					})}
					<div className={'Popup-printFeedback'}>{printFeedback}</div>
				</div>}

				{showEmailForm &&
				<div className="Popup-email">
					<div className="Popup-emailLabel">
						{(popupClass === 'printStories' ? popUpUiTexts.storyEmail : popUpUiTexts.planEmail)}
					</div>
					<div className="Popup-emailInput" onClick={(e) => {e.stopPropagation();}}>
						<input
							type="text"
							name="emailInputField"
							value={emailInputField}
							placeholder={popUpUiTexts.placeholderInput}
							onChange={(event) => {handleTextInputChange(event);}}
						/>
					</div>
					<div className="Popup-emailBtn">
						<Button
							type="button"
							class="sendEmail"
							text={popUpUiTexts.sendBtn}
							loading={isSendingEmail}
							onClick={() => {sendEmail();}}
						/>
					</div>
					<div className={'Popup-emailFeedback'}>{emailFeedback}</div>
				</div>}
			</div>
		</div>
	);
};

PopupInfo.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	isSendingEmail: PropTypes.bool.isRequired,
	showCloseBtn: PropTypes.bool.isRequired,
	showEmailForm: PropTypes.bool.isRequired,
	popupClass: PropTypes.string,
	popupTitle: PropTypes.string,
	popupText: PropTypes.string,
	popupBtns: PropTypes.array,
	emailInputField: PropTypes.string,
	emailFeedback: PropTypes.string,
	handleButtonClick: PropTypes.func.isRequired,
	handleTextInputChange: PropTypes.func.isRequired,
	sendEmail: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	printFeedback: PropTypes.string
};

export default PopupInfo;