import React from 'react';
import './chapter-four.scss';

const chapterFour = () => {
	return (
		<div className="chapterFour"> 
			<div className='chapterFour-background'/>
			
			<div className='chapterFour-waves'/>

			<div className="chapterFour-dust">	
				<div className="chapterFour-dustParticle chapterFour-dustParticle--1" />
				<div className="chapterFour-dustParticle chapterFour-dustParticle--2" />
			</div>
			<React.Fragment>
				<div className="chapterFour-sunbeam chapterFour-sunbeam--1" />
				<div className="chapterFour-sunbeam chapterFour-sunbeam--2" />
				<div className="chapterFour-sunbeam chapterFour-sunbeam--3" />
				<div className="chapterFour-sunbeam chapterFour-sunbeam--4" />
				<div className="chapterFour-sunbeam chapterFour-sunbeam--5" />
				<div className="chapterFour-sunbeam chapterFour-sunbeam--6" />
			</React.Fragment>


			<div className='chapterFour-foreground'/>
		</div>
	);
};


export default chapterFour;