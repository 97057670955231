const imagesData = [
	// logos
	'logos/logo-blivnaturligvisround.svg',
	'logos/logo-blivnaturligvis-light.svg',
	'logos/logo-blivnaturligvis.svg',
	'logos/logo-cgl-dark.svg',
	'logos/logo-cgl.svg',
	'logos/logo-nordeafonden.png',
	'logos/logo-nordeafonden-dark.png',

	'background-autumn.jpg',
	'ask-and-liv.png',
	'title-small.svg',
	'title.svg',
	'cookie/cookies.svg',

	'icon-close-green.svg',
	'icon-close.svg',
	'icon-menu-bullet.svg',
	'background-menu.png',
	'chapter-overview-1.svg',
	'chapter-overview-2.svg',
	'chapter-overview-3.svg',
	'chapter-overview-4.svg',
	'chapter-overview-5.svg',

	'badges/stretcher-inactive.svg',
	'badges/music-inactive.svg',
	'badges/hunt-inactive.svg',
	'badges/fishing-inactive.svg',
	'badges/fire-inactive.svg',

	'thumbs/animal-from-the-sea.png',
	'thumbs/animals-of-the-forest.png',
	'thumbs/facial-expression.png',
	'thumbs/massage-history.png',
	'background-forest.jpg',


	'backgrounds-story/chapter-1-thumb.png',
	'backgrounds-story/chapter-2-thumb.png',
	'backgrounds-story/chapter-3-thumb.png',
	'backgrounds-story/chapter-4-thumb.png',
	'backgrounds-story/chapter-5-thumb.png',
	'backgrounds-story/chapter-6-thumb.png',
	'backgrounds-story/chapter-7-thumb.png',
	'backgrounds-story/chapter-8-thumb.png',
	'backgrounds-story/chapter-9-thumb.png',
	'backgrounds-story/chapter-10-thumb.png',

	'icon-chapter-1.svg',
	'icon-chapter-2.svg',
	'icon-chapter-3.svg',
	'icon-chapter-4.svg',
	'icon-chapter-5.svg',

	'icon-checkbox.svg',
	'icon-checkmark-lightgreen.svg',
	'icon-checkmark.svg',
	'icon-circle.png',

	'icon-download.svg',
	'icon-info.svg',

	'icon-next.svg',

	'icon-pdf-white.svg',
	'icon-pdf.svg',
	'icon-prev.svg',
	'icon-print.svg',
	'icon-read-close.svg',




	'badges/fire-active.svg',
	'badges/fishing-active.svg',
	'badges/hunt-active.svg',
	'badges/music-active.svg',
	'badges/stretcher-active.svg',

	'badges/rewards/background.png',
	'badges/rewards/fire-glow.png',
	'badges/rewards/fire.png',
	'badges/rewards/fishing-glow.png',
	'badges/rewards/fishing.png',
	'badges/rewards/foreground.png',
	'badges/rewards/hunt-glow.png',
	'badges/rewards/hunt.png',
	'badges/rewards/music-glow.png',
	'badges/rewards/music.png',
	'badges/rewards/stretcher-glow.png',
	'badges/rewards/stretcher.png',

	'background-audio.svg',

	'background-popup.png',
	'background-tracks.svg',
	'box-selected.svg',
	'box-story.svg',
	'box.svg',
	'chapter-nav-1-selected.svg',
	'chapter-nav-1.svg',
	'chapter-nav-2-selected.svg',
	'chapter-nav-2.svg',
	'chapter-nav-3-selected.svg',
	'chapter-nav-3.svg',
	'chapter-nav-4-selected.svg',
	'chapter-nav-4.svg',
	'chapter-nav-5-selected.svg',
	'chapter-nav-5.svg',

	'characters-ask-and-liv.png',
	'icon-arrow-back-red.svg',
	'icon-arrow-back-white.svg',
	'icon-arrow-down.svg',
	'icon-arrow-up-new.svg',

	'icon-arrow-up.svg',

	'icon-book.svg',
	'icon-book2.svg',

	'icon-read-new.svg',
	'icon-read-story.svg',
	'icon-read-two.svg',
	'icon-read.svg',
	'icon-zoom.svg',

	'backgrounds-story/animation/chapter-1/background.png',
	'backgrounds-story/animation/chapter-1/deer-ear.png',
	'backgrounds-story/animation/chapter-1/deer.png',
	'backgrounds-story/animation/chapter-1/fog.png',
	'backgrounds-story/animation/chapter-1/foreground.png',

	'backgrounds-story/animation/chapter-2/background.png',
	'backgrounds-story/animation/chapter-2/foreground.png',
	'backgrounds-story/animation/chapter-2/hare-01.png',
	'backgrounds-story/animation/chapter-2/hare-02.png',
	'backgrounds-story/animation/chapter-2/hare-03.png',

	'backgrounds-story/animation/chapter-3/background.png',
	'backgrounds-story/animation/chapter-3/foreground.png',

	'backgrounds-story/animation/chapter-3/tjur-01.png',
	'backgrounds-story/animation/chapter-3/tjur-02.png',
	'backgrounds-story/animation/chapter-3/tjur-03.png',

	'backgrounds-story/animation/chapter-4/background.png',
	
	'backgrounds-story/animation/chapter-4/water-05.png',
	'backgrounds-story/animation/chapter-4/water-04.png',
	'backgrounds-story/animation/chapter-4/water-03.png',
	'backgrounds-story/animation/chapter-4/water-02.png',
	'backgrounds-story/animation/chapter-4/water-01.png',

	'backgrounds-story/animation/chapter-5/background.png',
	'backgrounds-story/animation/chapter-5/squirrel-01.png',
	'backgrounds-story/animation/chapter-5/squirrel-04.png',
	'backgrounds-story/animation/chapter-5/squirrel-03.png',
	'backgrounds-story/animation/chapter-5/squirrel-02.png',

	'backgrounds-story/animation/chapter-6/background.png',
	'backgrounds-story/animation/chapter-6/fog.png',
	'backgrounds-story/animation/chapter-6/foreground.png',
	'backgrounds-story/animation/chapter-6/water-01.png',
	'backgrounds-story/animation/chapter-6/water-02.png',
	'backgrounds-story/animation/chapter-6/water-03.png',
	'backgrounds-story/animation/chapter-6/water-04.png',
	'backgrounds-story/animation/chapter-6/water-05.png',
	'backgrounds-story/animation/chapter-6/water-06.png',

	'backgrounds-story/animation/chapter-7/background.png',
	'backgrounds-story/animation/chapter-7/clouds.png',
	'backgrounds-story/animation/chapter-7/foreground-01.png',
	'backgrounds-story/animation/chapter-7/foreground-02.png',
	'backgrounds-story/animation/chapter-7/grass-1.png',
	'backgrounds-story/animation/chapter-7/grass-2.png',
	'backgrounds-story/animation/chapter-7/grass-3.png',
	'backgrounds-story/animation/chapter-7/water-01.png',
	'backgrounds-story/animation/chapter-7/water-02.png',

	'backgrounds-story/animation/chapter-8/background.png',
	'backgrounds-story/animation/chapter-8/fog.png',
	'backgrounds-story/animation/chapter-8/foreground.png',
	'backgrounds-story/animation/chapter-8/glow.png',

	'backgrounds-story/animation/chapter-9/background.png',
	'backgrounds-story/animation/chapter-9/clouds.png',
	'backgrounds-story/animation/chapter-9/foreground.png',
	'backgrounds-story/animation/chapter-9/grass.png',
	'backgrounds-story/animation/chapter-9/water-04.png',
	'backgrounds-story/animation/chapter-9/water-03.png',
	'backgrounds-story/animation/chapter-9/water-02.png',
	'backgrounds-story/animation/chapter-9/water-01.png',

	'backgrounds-story/animation/chapter-10/background.png',
	'backgrounds-story/animation/chapter-10/water-01.png',
	'backgrounds-story/animation/chapter-10/water-02.png',
	'backgrounds-story/animation/chapter-10/water-03.png',

	'backgrounds-story/animation/shared/dust1.png',
	'backgrounds-story/animation/shared/dust2.png',
	'backgrounds-story/animation/shared/sunbeams1-blur1.png',
	'backgrounds-story/animation/shared/sunbeams1-blur2.png',
	'backgrounds-story/animation/shared/sunbeams1-blur3.png',
	'backgrounds-story/animation/shared/sunbeams1-blur4.png',
	'backgrounds-story/animation/shared/sunbeams1-blur5.png',
	'backgrounds-story/animation/shared/sunbeams1.png',
	'backgrounds-story/animation/shared/sunbeams2-blur1.png',
	'backgrounds-story/animation/shared/sunbeams2-blur2.png',
	'backgrounds-story/animation/shared/sunbeams2-blur3.png',
	'backgrounds-story/animation/shared/sunbeams2-blur4.png',
	'backgrounds-story/animation/shared/sunbeams2-blur5.png',
	'backgrounds-story/animation/shared/sunbeams2.png',

	'illustrations/activity-01.png',
	'illustrations/activity-02.png',
	'illustrations/activity-03.png',
	'illustrations/activity-04.png',
	'illustrations/activity-05.png',
	'illustrations/activity-06.png',
	'illustrations/activity-07.png',
	'illustrations/activity-08.png',
	'illustrations/activity-09.png',
	'illustrations/activity-10.png',
	'illustrations/activity-11.png',
	'illustrations/activity-12.png',
	'illustrations/activity-13.png',
	'illustrations/activity-14.png',
	'illustrations/activity-15.png',
	'illustrations/activity-16.png',
	'illustrations/activity-17.png',
	'illustrations/activity-18.png',
	'illustrations/activity-19.png',
	'illustrations/activity-20.png',
	'illustrations/activity-21.png',
	'illustrations/activity-22.png',
	'illustrations/activity-23.png',
	'illustrations/activity-24.png',
	'illustrations/activity-25.png',
	'illustrations/activity-26.png',
	'illustrations/activity-27.png',
	'illustrations/activity-28.png',
	'illustrations/activity-29.png',
	'illustrations/activity-30.png',
	'illustrations/activity-31.png',
	'illustrations/activity-32.png',
	'illustrations/activity-33.png',
	'illustrations/activity-34.png',
	'illustrations/activity-35.png',
	'illustrations/activity-36.png',
	'illustrations/activity-37.png',
	'illustrations/activity-38.png',
	'illustrations/activity-39.png',
	'illustrations/activity-40.png',
	'illustrations/activity-41.png',
	'illustrations/activity-42.png',
	'illustrations/activity-43.png',
	'illustrations/activity-44.png',
	'illustrations/activity-45.png',
	'illustrations/activity-46.png',
	'illustrations/activity-47.png',
	'illustrations/activity-48.png',
	'illustrations/activity-49.png',
	'illustrations/activity-50.png',
	'illustrations/activity-51.png',
	'illustrations/activity-52.png',
	'illustrations/activity-53.png',
	'illustrations/activity-54.png',
	'illustrations/activity-55.png',
	'illustrations/activity-56.png',
	'illustrations/activity-57.png',
	'illustrations/activity-58.png',
	'illustrations/activity-59.png',
	'illustrations/activity-60.png',
	'illustrations/activity-61.png',
	'illustrations/activity-62.png',
	'illustrations/activity-63.png',
	'illustrations/activity-64.png',
	'illustrations/activity-65.png',
	'illustrations/activity-66.png',
	'illustrations/activity-67.png',
	'illustrations/activity-68.png',
	'illustrations/activity-69.png',
	'illustrations/activity-70.png',
	'illustrations/activity-71.png',
	'illustrations/activity-72.png',
	'illustrations/activity-73.png',
];

export default imagesData;