const AudioData = [
	{
		id: 'chapter-1.mp3',
	},
	{
		id: 'chapter-2.mp3',
	},
	{
		id: 'chapter-3.mp3',
	},
	{
		id: 'chapter-4.mp3',
	},
	{
		id: 'chapter-5.mp3',
	},
	{
		id: 'chapter-6.mp3',
	},
	{
		id: 'chapter-7.mp3',
	},
	{
		id: 'chapter-8.mp3',
	},
	{
		id: 'chapter-9.mp3',
	},
	{
		id: 'chapter-10.mp3',
	},
];

export default AudioData;