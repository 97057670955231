import React, { useState, useEffect } from 'react';
import './chapter-three.scss';

const chapterThree = () => {

	
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [animateTjur, setAnimateTjur] = useState(true);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		// random values between 2500-5000
		const interval = setInterval(() => {setAnimateTjur(!animateTjur);}, 
			(Math.floor(Math.random() * 5) + 2.5) * 1000 );

		return () => {
			clearInterval(interval);
		};
	}, [animateTjur]);

	return (
		<div className="chapterThree"> 
			<div className='chapterThree-background'/>

			<div className={'chapterThree-tjur ' + (animateTjur ? 'chapterThree-tjur--animate' : '')}/>

			<React.Fragment>
				<div className="chapterThree-sunbeam chapterThree-sunbeam--1" />
				<div className="chapterThree-sunbeam chapterThree-sunbeam--2" />
				<div className="chapterThree-sunbeam chapterThree-sunbeam--3" />
				<div className="chapterThree-sunbeam chapterThree-sunbeam--4" />
				<div className="chapterThree-sunbeam chapterThree-sunbeam--5" />
				<div className="chapterThree-sunbeam chapterThree-sunbeam--6" />
			</React.Fragment>

			<div className="chapterThree-dust">	
				<div className="chapterThree-dustParticle chapterThree-dustParticle--1" />
				<div className="chapterThree-dustParticle chapterThree-dustParticle--2" />
			</div>


			<div className='chapterThree-foreground'/>
		</div>
	);
};


export default chapterThree;