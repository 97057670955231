/* eslint-disable camelcase */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test (includes demo) */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyBxoePc9cfeW44DI19mm1uttfbpozLARPw',
		authDomain: 'cgl-hunter-kids2-test.firebaseapp.com',
		projectId: 'cgl-hunter-kids2-test',
		storageBucket: 'cgl-hunter-kids2-test.appspot.com',
		messagingSenderId: '433366370531',
		appId: '1:433366370531:web:bfc88b9a788178b2065dd6'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyCU443H1pnhYicodd6yOv13mgKslQyqSq0',
		authDomain: 'cgl-hunter-kids2-production.firebaseapp.com',
		projectId: 'cgl-hunter-kids2-production',
		storageBucket: 'cgl-hunter-kids2-production.appspot.com',
		messagingSenderId: '66354743960',
		appId: '1:66354743960:web:562e34766c1778728c9eed'
	};
}


/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;