import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

/**
 * Get audio src
 * @param {string} type 
 * @param {string} languageId 
 * @param {string} moduleId 
 * @param {object} audioData 
 */
export function getAudioSrc(audioType) {

	let fileName = null;

	fileName = 'audio/' + audioType;

	/* Get link to audio file */
	return new Promise((resolve) => {
		if (!fileName) resolve({status: 'error', error: 'no-such-file'});

		const storageRef = firebase.storage().ref();
		storageRef.child(fileName).getDownloadURL()
			.then((src) => {resolve({status: 'success', src: src});})
			.catch((error) => {resolve({status: 'error', error: error});});
	});
}