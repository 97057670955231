import React from 'react';
import PropTypes from 'prop-types';
import chapterData from 'data/chapter-data';
import ChapterConfigure from './chapter-configure';
import ActivityDescription from './activity-description';
import StoryController from 'components/story/story-controller';
import BadgesController from 'components/badges/badges-controller';

class ChapterConfigureController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedActivity: null,
			animateMaterialsIcon: false,
			showActivityDescription: false,
			showActivityStory: false,
			showBadges: false,
		};
		this.timeout = null;
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		clearTimeout(this.timeout);
	};

	/**
	 * Component updated
	 * @param {object} prevProps
	 */
	componentDidUpdate = (prevProps) => {
		if (prevProps.chapterIndex !== this.props.chapterIndex) {
			this.setState({
				selectedActivity: null,
				showActivityDescription: false,
				showActivityStory: false,
			});
		}
	};

	/**
	 * Start / stop animation of material icon
	 * @param {bool} animateMaterialsIcon
	 */
	handleAnimateMaterialsIcon = (animateMaterialsIcon) => {
		this.setState({animateMaterialsIcon: animateMaterialsIcon}, () => {
			if (animateMaterialsIcon) {
				this.timeout = setTimeout(() => {this.setState({animateMaterialsIcon: false});}, 800);
			} else {
				clearTimeout(this.timeout);
			}
		});
	};

	/**
	 * Show / hide activity popup
	 * @param {string} activityId
	 */
	toggleActivityPopup = (activityId = null) => {
		let selectedActivity = null;
		if (activityId) {
			chapterData[this.props.chapterIndex].themes.forEach((theme) => {
				theme.activities.forEach((activity) => {
					if (activity.id === activityId) selectedActivity = JSON.parse(JSON.stringify(activity));
				});
			});
		}
		this.setState({selectedActivity: selectedActivity});
	};

	/**
	 * Show / hide activity description 
	 * @param {bool} showActivityDescription
	 */
	toggleActivityDescription = (showActivityDescription) => {
		if (this.state.selectedActivity === null && showActivityDescription) return;
		this.setState({showActivityDescription: showActivityDescription});
	};
 
	/**
	 * Show / hide activity story
	 * @param {bool} showActivityStory
	 */
	toggleActivityStory = (showActivityStory) => {
		if (this.state.selectedActivity === null && showActivityStory) return;
		this.setState({showActivityStory: showActivityStory, selectedActivity: null});
	};

	/**
	* Show / hide activity story
	* @param {bool} showActivityStory
	*/
	toggleActivityStoryWithActivityId = (showActivityStory, selectedActivity) => {
		// if (this.state.selectedActivity === null && showActivityStory) return;
		this.setState({showActivityStory:showActivityStory, selectedActivity: selectedActivity});
	};

	/**
	 * Show / hide badges page
	 * @param {bool} showBadges
	 */
	toggleBadges = (showBadges) => {
		if (this.state.selectedActivity === null && showBadges) return;
		this.setState({showBadges: showBadges});
	};

	/**
	 * Render component
	 */
	render = () => {	
		return (
			<React.Fragment>
				<ChapterConfigure 
					animateMaterialsIcon = {this.state.animateMaterialsIcon}
					chapterIndex = {this.props.chapterIndex}
					chapter = {chapterData[this.props.chapterIndex]}
					selectedActivity = {this.state.selectedActivity}
					selectedActivityIds = {this.props.selectedActivityIds}
					openPopup = {this.props.openPopup}
					handleAnimateMaterialsIcon = {this.handleAnimateMaterialsIcon}
					toggleActivityPopup = {this.toggleActivityPopup}
					toggleActivity = {this.props.toggleActivity}
					toggleActivityDescription = {this.toggleActivityDescription}
					toggleActivityStory = {this.toggleActivityStory}
					selectPage = {this.props.selectPage}
					selectSubPage = {this.selectSubPage}
					toggleActivityStoryWithActivityId={this.toggleActivityStoryWithActivityId}
				/>
				{this.state.showActivityDescription && 
					<ActivityDescription 
						chapters = {chapterData}
						selectedActivity = {this.state.selectedActivity}
						selectedActivityIds = {this.props.selectedActivityIds}
						selectedBadgeIds={this.props.selectedBadgeIds}
						toggleActivityDescription = {this.toggleActivityDescription}
						toggleActivityStory={this.toggleActivityStory}
						toggleBadges={this.toggleBadges}
						openPopup= {this.props.openPopup}
					/>
				}
				{this.state.showActivityStory && 
					<StoryController
						type = "configure"
						limitAnimations={this.props.limitAnimations}
						chapterIndex = {this.props.chapterIndex}
						activity = {this.state.selectedActivity}
						toggleStory = {this.toggleActivityStory}
						openPopup={this.props.openPopup}
					/>
				}
				{this.state.showBadges &&
					<BadgesController
						prevPage = "configure"
						badgesIntroSeen = {this.props.badgesIntroSeen}
						selectedBadgeIds = {this.props.selectedBadgeIds} 
						toggleBadge = {this.props.toggleBadge}
						handleBadgesIntroSeen = {this.props.handleBadgesIntroSeen}
						selectPage = {this.props.selectPage}
						toggleBadges = {this.toggleBadges}
						openPopup = {this.props.openPopup}
					/>
				}
			</React.Fragment>
		);
	};
}

ChapterConfigureController.propTypes = {
	badgesIntroSeen: PropTypes.bool.isRequired,
	limitAnimations: PropTypes.bool.isRequired,
	chapterIndex: PropTypes.number.isRequired,
	selectedActivityIds: PropTypes.array.isRequired,
	selectedBadgeIds: PropTypes.array.isRequired,
	handleBadgesIntroSeen: PropTypes.func.isRequired,
	selectPage: PropTypes.func.isRequired,
	toggleActivity: PropTypes.func.isRequired,
	toggleBadge: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired,
};

export default ChapterConfigureController;