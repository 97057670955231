import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {generalUiTexts, popUpUiTexts} from 'data/ui-texts';
import {handlePrintStories} from 'helpers/print-helper';
import BackButton from 'components/ui/button/back-button';
import Audio from 'components/ui/audio/audio';
import PrintButton from 'components/ui/button/print-button';
import chapterOne from './animations/chapter-one';
import chapterTwo from './animations/chapter-two';
import chapterThree from './animations/chapter-three';
import chapterFour from './animations/chapter-four';
import chapterFive from './animations/chapter-five';
import chapterSix from './animations/chapter-six';
import chapterSeven from './animations/chapter-seven';
import chapterEight from './animations/chapter-eight';
import chapterNine from './animations/chapter-nine';
import chapterTen from './animations/chapter-ten';

import './story.scss';

const animations = {
	'part1-1': {component: chapterOne},
	'part1-2': {component: chapterTwo},
	'part2-1': {component: chapterThree},
	'part2-2': {component: chapterFour},
	'part3-1': {component: chapterFive},
	'part3-2': {component: chapterSix},
	'part4-1': {component: chapterSeven},
	'part4-2': {component: chapterEight},
	'part5-1': {component: chapterNine},
	'part5-2': {component: chapterTen},
};

const Story = (props) => {
	let {
		type,
		limitAnimations,
		showStoryAnimation,
		showAudio,
		containerWidth,
		containerHeight,
		activity,
		allStories,
		toggleStory,
		openPopup
	} = props;
	
	let audioFile = activity.storyAudio;
	let audioType = (showStoryAnimation ? 'animation' : 'text');

	const [isReading, setIsReading] = useState(false);

	let AnimationComponent = null;
	if (showStoryAnimation && animations.hasOwnProperty(activity.id)) {
		AnimationComponent = animations[activity.id].component;
	}

	return (
		<div className={'Story' + (type  ? ' Story--' + type : '')}>
			{/* Animation */}
			{AnimationComponent && 
				<div className='Story-animation' >
					<AnimationComponent 
						limitAnimations={limitAnimations}
						containerWidth={containerWidth} 
						containerHeight={containerHeight}
					/>
				</div>
			}

			{/* Header */}
			<div className={'Story-header' + (isReading ? ' Story-header--open' : '')}>
				{/* Audio */}
				{(audioFile && audioFile.length > 0) && 
					<div className={'Story-audio Story-audio--' + audioType	
					+ (!showAudio ? ' Story-audio--hidden' : '')}>
						<Audio audioType={audioType} audioFile={audioFile} />
						<div className='Story-audio-Title'>
							<span>
								{generalUiTexts.chapter + ' ' + activity.storyNumber + ': ' + activity.storyTitle}
							</span>
						</div>
					</div>
				}
				{/* Reading button */}
				<div className={'Story-iconReading' + (isReading ? ' Story-iconReading--open' : '')} 
					onClick={()=>{setIsReading(!isReading);}}> 
					<div className={'Story-iconReading-arrow' + (isReading ? ' Story-iconReading-arrow--open' : '')}/>
				</div>
				
			</div>
			
			{/* Body */}
			<div className={'Story-bodyWrapper' + (isReading ? ' Story-bodyWrapper--open' : '')}>
				<div className={'Story-body'}>
					<div className={'Story-info' + (isReading ? ' Story-info--open' : '')}>
						<span>{generalUiTexts.chapter + ' ' + activity.storyNumber + ':'}</span>
					</div>
					<div className={'Story-title' + (isReading ? ' Story-title--open' : '')}>
						<span>{activity.storyTitle}</span>
					</div>
					
					<div className={'Story-text' 
					+ (isReading ? ' Story-text--open' : '')}>{renderMarkdown(activity.storyFile)}</div>
				</div>
			</div>

			{/* Print button */}
			<PrintButton 
				page={'story ' + (isReading ? ' story--open' : '')}
				onClick = {() => {
					if (!isReading) return;
					openPopup(
						'printStories', null, null, [],
						[
							{
								class: 'printStory', 
								text: popUpUiTexts.printStory, 
								action: handlePrintStories,
								actionParams: [[{
									number: activity.storyNumber, 
									title: activity.storyTitle,
									text: activity.storyFile
								}]]
							},
							{
								class: 'printStories', 
								text: popUpUiTexts.printStories, 
								action: handlePrintStories, 
								actionParams: [allStories]
							}
						],
						true, 
						true,
						allStories
					);
				}}
			/>

			{/* Back button */}
			<BackButton 
				onClick={() => {
					toggleStory((type === 'configure' ? false : ''));
				}}
			/>
		</div>
	);
};

Story.defaultProps = {
	type: null,
	showStoryAnimation: false,
	showAudio: true
};

Story.propTypes = {
	type: PropTypes.string,
	showStoryAnimation: PropTypes.bool,
	limitAnimations: PropTypes.bool.isRequired,
	showAudio: PropTypes.bool, 
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired,
	activity: PropTypes.object.isRequired,
	allStories: PropTypes.array.isRequired,
	toggleStory: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired
};

export default Story;