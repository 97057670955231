const pdfsData = [
	{
		title: 'Dyr',
		subtitle: 'fra skoven',
		orientation: 'portrait',
		id: 'animals-of-the-forest'
	},
	{
		title: 'Dyr',
		subtitle: 'fra havet',
		orientation: 'portrait',
		id: 'animal-from-the-sea'
	},
	{
		title: 'Ask & Liv',
		subtitle: 'Ansigtsudtryk',
		orientation: 'portrait',
		id: 'facial-expression'
	},
	{
		title: 'Massagehistorie',
		subtitle: 'Liv i skoven',
		orientation: 'portrait',
		id: 'massage-history'
	},
	{
		title: 'Massagehistorie',
		subtitle: 'Ask og havdyrene',
		orientation: 'portrait',
		id: 'massage-history2'
	},
	{
		title: 'Mærker',
		subtitle: 'til farvelægning',
		orientation: 'portrait',
		id: 'colouring-stickers'
	},
	{
		title: 'Dialogkort',
		subtitle: '',
		orientation: 'landscape',
		id: 'dialogue-cards'
	},
	{
		title: 'Historien med flip over-illustrationer',
		subtitle: '',
		orientation: 'landscape',
		id: 'flipover-illustrations'
	}
];

export default pdfsData;