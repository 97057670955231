import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import BackButton from 'components/ui/button/back-button';
import './whole-story.scss';
import Audio from 'components/ui/audio/audio';
import { getAudioSrc } from 'helpers/audio-helper';

const WholeStory = ({prevPage, selectPage}) => {
	let audioFile = 'wholeStory.mp3';
	const [isloading, setIsLoading] = useState(false);

	const handleDownloadAudio = (e) => {
		setIsLoading(true);
		e.preventDefault();
		getAudioSrc(
			audioFile
		).then((result) => {
			if (result.status === 'success') {
				const xhr = new XMLHttpRequest();
				xhr.addEventListener('load', () => {setIsLoading(false);});
				xhr.responseType = 'blob';
				xhr.onload = (event) => {
					const blob = xhr.response;
					let link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.target = '_blank';
					link.setAttribute('download', audioFile);
					document.getElementsByTagName('body')[0].appendChild(link);
					link.click();
				};
				xhr.open('GET', result.src);
				xhr.send();
			}
		});
	};

	return (
		<div className="WholeStory">
			{/* Back button */}
			<BackButton onClick={() => {selectPage(prevPage);}}/>

			{/* Audio */}
			{(audioFile && audioFile.length > 0) && 
				<div className={'WholeStory-audio'}>
					<Audio audioType={'whole'} audioFile={audioFile} />
				</div>
			}

			{/* Body */}
			<div className="WholeStory-body">
				<span>{generalUiTexts.wholestory}</span>
				<div className="WholeStory-subtitle"><span>
					{generalUiTexts.wholestorySubtitle}
				</span></div>
			</div>
			<div className={'WholeStory-downloadBtn ' + (isloading ? 'WholeStory-downloadBtn-loading' : '')}
				onClick={(e) => { handleDownloadAudio(e); }}>
			</div>
		</div>
	);
};

WholeStory.propTypes = {
	prevPage: PropTypes.string.isRequired,
	selectPage: PropTypes.func.isRequired,
};

export default WholeStory;