import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import BackButton from 'components/ui/button/back-button';
import './about.scss';
import CGL from 'components/cgl-logo/cgl';


const About = ({selectPage}) => {
	/* Load about text */
	let aboutText = null;
	try {
		aboutText = require('data/pages/about.md');
	} catch (e) {
		console.error('Could not find text: data/pages/about.md');
	}

	return (
		<div className="About">
			<BackButton onClick={() => {selectPage('chapterOverview');}} />
			
			<div className="About-body">
				<div className="About-text"><span>{aboutText && renderMarkdown(aboutText.default)}</span></div>
				<div className='About-nordea' onClick={() => {window.open('https://nordeafonden.dk/', '_blank', 'noopener,noreferrer');}}/>
				<div className="About-logo" onClick={() => {window.open('https://blivnaturligvis.dk/', '_blank', 'noopener,noreferrer');}}/>
				<CGL type={'dark'} classes={['about']}/>
			</div>
		</div>
	);
};

About.propTypes = {
	selectPage: PropTypes.func.isRequired,
};

export default About;