import React from 'react';
import PropTypes from 'prop-types';
import './chapter-one.scss';

const chapterOne = () => {
	return (
		<div className="chapterOne">
			<div className='chapterOne-background'/>
			<div className='chapterOne-deer'>
				<div className='chapterOne-deer-ear'/>
			</div>
			<div className='chapterOne-fog'/>
			<div className='chapterOne-foreground'/>
		</div>
	);
};


chapterOne.propTypes = {
	limitAnimations: PropTypes.bool.isRequired,
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired
};


export default chapterOne;