import React from 'react';
import PropTypes from 'prop-types';
import pdfsData from 'data/pdfs-data';
import {renderMarkdown} from 'helpers/text-helper';
import BackButton from 'components/ui/button/back-button';
import './resources.scss';

const Resources = ({selectPage}) => {
	/* Load resources text */
	let resourcesText = null;
	try {
		resourcesText = require('data/pages/resources.md');
	} catch (e) {
		console.error('Could not find text: data/pages/resources.md');
	}

	return (
		<div className="Resources">
			{/* Back button */}
			<BackButton onClick={() => {selectPage('chapterOverview');}}/>

			<div className="Resources-content">
				{/* Header */}
				<div className="Resources-header">
					<div className="Resources-text">
						{resourcesText && renderMarkdown(resourcesText.default)}
					</div>				
				</div>

				{/* Body */}
				<div className="Resources-body">
					{/* Loop over pdfs */}
					{pdfsData.map((pdf, index) => {
						let url = require('../../assets/pdfs/' + pdf.id + '.pdf');
						let thumb = require('../../assets/images/thumbs/' + pdf.id + '.png');
						let className = 'Resources-pdf ' + pdf.orientation;
						return (
							<div key={index} className={className} id={'Pdf-' + index}>
								<a href={url} target="_blank" rel="noopener noreferrer">
									<img src={thumb} alt="pdf thumb" />
								</a>
								<div className="Resources-pdfTitle">{pdf.title}</div>
								<div className="Resources-pdfSubTitle">{pdf.subtitle}</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};



	
Resources.propTypes = {
	selectPage: PropTypes.func.isRequired,
};
export default Resources;