import React from 'react';
import PropTypes from 'prop-types';
import './chapter-ten.scss';

const chapterTen = () => {
	return (
		<div className="chapterTen">
			<div className="chapterTen-animation"/>
		</div>
	);
};


chapterTen.propTypes = {
	limitAnimations: PropTypes.bool.isRequired,
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired
};


export default chapterTen;