import React from 'react';
import PropTypes from 'prop-types';
import chapterData from 'data/chapter-data';
import Stories from './stories';
import Story from './story';

class StoryController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showStoryAnimation: true,
			showAudio: true,
			chapterIndex: this.props.chapterIndex,
			activity: this.props.activity,
			containerWidth: 0,
			containerHeight: 0
		};
	}


	componentDidMount = () => {
		this.getContainerDimensions();
		window.addEventListener('resize', this.getContainerDimensions, true);
		window.addEventListener('orientationchange', this.getContainerDimensions, true);
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.getContainerDimensions, true);
		window.removeEventListener('orientationchange', this.getContainerDimensions, true);
	};

	getContainerDimensions = () => {
		let container = document.getElementById('app');
		if (container) {
			let containerWidth = container.offsetWidth;
			let containerHeight = container.offsetHeight;
			this.setState({containerWidth, containerHeight});
		}
	};

	/**
	 * Show / hide story
	 * @param {number} chapterIndex 
	 * @param {number} themeIndex 
	 * @param {number} activityIndex 
	 */	
	toggleStory = (chapterIndex = null, themeIndex = null, activityIndex = null) => {
		let activity = null;
		if (chapterIndex !== null && themeIndex !== null && activityIndex !== null) {
			activity = chapterData[chapterIndex].themes[themeIndex].activities[activityIndex];
		}

		this.setState({
			showStoryAnimation: true,
			showAudio: true,
			chapterIndex: chapterIndex, 
			activity: activity,
		});
	};

	/**
	 * Show / hide story animation
	 */
	toggleStoryAnimation = (showStoryAnimation) => {
		this.setState({
			showStoryAnimation: showStoryAnimation, 
			showAudio: true
		});
	};

	/**
	 * Show / hide audio (only when story is showing animation)
	 */
	toggleAudio = (showAudio) => {
		this.setState({showAudio: showAudio});
	};

	/**
	 * Render component
	 */
	render = () => {
		if (this.state.activity) {
			let allStories = [];
			chapterData.forEach((chapter) => {
				chapter.themes.forEach((theme) => {
					theme.activities.forEach((chapterActivity) => {
						if (chapterActivity.storyNumber && chapterActivity.storyTitle && chapterActivity.storyFile) {
							allStories.push({
								number: chapterActivity.storyNumber,
								title: chapterActivity.storyTitle,
								text: chapterActivity.storyFile
							});
						}
					});
				});
			});

			return (
				<Story
					type={this.props.type}
					limitAnimations={this.props.limitAnimations}
					showStoryAnimation = {this.state.showStoryAnimation}
					showAudio = {this.state.showAudio}
					containerWidth = {this.state.containerWidth}
					containerHeight = {this.state.containerHeight}
					chapterIndex = {this.state.chapterIndex}	
					activity = {this.state.activity}
					allStories = {allStories}
					toggleStory={this.props.type === 'default' ? this.toggleStory : this.props.toggleStory}
					toggleStoryAnimation={this.toggleStoryAnimation}
					toggleAudio={this.toggleAudio}
					openPopup={this.props.openPopup}
				/>
			);
		}

		return (
			<Stories
				prevPage={this.props.prevPage}
				toggleStory={this.toggleStory}
				selectPage={this.props.selectPage}
				openPopup = {this.props.openPopup}
			/>
		);
	};
}

StoryController.defaultProps = {
	type: 'default',
	chapterIndex: null,
	activity: null
};

StoryController.propTypes = {
	type: PropTypes.string,
	limitAnimations: PropTypes.bool.isRequired,
	chapterIndex: PropTypes.number,
	activity: PropTypes.object,
	prevPage: PropTypes.string,
	selectPage: PropTypes.func,
	toggleStory: PropTypes.func,
	openPopup: PropTypes.func.isRequired
};


export default StoryController;