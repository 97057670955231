import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import chapterData from 'data/chapter-data';
import {renderMarkdown} from 'helpers/text-helper';
import BackButton from 'components/ui/button/back-button';
import './stories.scss';
import { getAudioSrc } from 'helpers/audio-helper';
import popupInfoText from 'data/pages/infoHistory.md';
import AudioData from 'data/audio-files-data';
import { generalUiTexts } from 'data/ui-texts';

const Stories = ({ selectPage, toggleStory, openPopup }) => {
	/* Load stories text */
	let storiesText = null;
	try {
		storiesText = require('data/pages/stories.md');
	} catch (e) {
		console.error('Could not find text: data/pages/stories.md');
	}
	let popupTextLinks = [];

	let pos = { top: 0, left: 0, x: 0, y: 0 };
	
	const mouseDownHandler = (e) => {
		if (!refele.current) return;
		
		// Change the cursor and prevent user from selecting the text
		refele.current.style.cursor = 'grabbing';
		refele.current.style.userSelect = 'none';

		pos = {
			// The current scroll
			left: refele.current.scrollLeft,
			top: refele.current.scrollTop,
			// Get the current mouse position
			x: e.clientX,
			y: e.clientY,
		};

		document.addEventListener('mousemove', mouseMoveHandler);
		document.addEventListener('mouseup', mouseUpHandler);
	};
	/**
	 * Updates the scroll position
	*/ 
	const mouseMoveHandler = (e) => {
		setIsDragging(true);
		// How far the mouse has been moved
		const dx = e.clientX - pos.x;
		const dy = e.clientY - pos.y;
	
		// Scroll the element
		refele.current.scrollTop = pos.top - dy;
		refele.current.scrollLeft = pos.left - dx;
	};
	/**
	 * Stops updates on mouse button release
	*/ 
	const mouseUpHandler = (e) => {
		document.removeEventListener('mousemove', mouseMoveHandler);
		document.removeEventListener('mouseup', mouseUpHandler);
		refele.current.style.cursor = 'grab';
		refele.current.style.removeProperty('user-select');
		setTimeout(() => {setIsDragging(false);}, 250);
	};


	const [loadingIndex, setLoadingIndex] = useState(null);

	
	/**
	 * Handles audio download
	 */
	const handleDownloadAudio = (e, audio, index) => {
		setLoadingIndex(index);
		e.preventDefault();
		getAudioSrc(
			audio
		).then((result) => {
			if (result.status === 'success') {
				const xhr = new XMLHttpRequest();
				xhr.addEventListener('load', () => {setLoadingIndex(null);});
				xhr.responseType = 'blob';
				xhr.onload = (event) => {
					const blob = xhr.response;
					let link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.target = '_blank';
					link.setAttribute('download', audio);
					document.getElementsByTagName('body')[0].appendChild(link);
					link.click();
				};
				xhr.open('GET', result.src);
				xhr.send();	
			}
		});
	};
	
	const togleDownloadPopup = (e) => {
		setShowDownloadPopup(!showDownloadPopup);
	};
	const [showDownloadPopup, setShowDownloadPopup] = useState(false);

	const refele = useRef();

	const [isDragging, setIsDragging] = useState(false);

	return (
		<div className="Stories">
			{/* Back button */}
			<BackButton onClick={() => {selectPage('chapterOverview');}}/>

			{/* Next button */}
			<div 
				className="Stories-scrollNextbutton"
				onClick = {() => {
					refele.current && refele.current.scrollBy({
						top: 0,
						left: 500,
						behavior: 'smooth'
					  });
				}}
			/>
			{/* Prev button */}
			<div 
				className="Stories-scrollBackbutton"
				onClick = {() => {
					refele.current && refele.current.scrollBy({
						top: 0,
						left: -500,
						behavior: 'smooth'
				  });
				}}
			/>

			{/* Info popup button */}
			<div 
				className="Stories-infoIcon"
				onClick = {() => {openPopup('infoPopup', null, popupInfoText, popupTextLinks, null, true);}}
			/>
			{/* icon-read-story */}
			<div className="Stories-wholeStory"
				onClick = {() => {selectPage('wholestory');}}>
				<div className={'Stories-wholeStoryIcon'}></div>
			</div>

			{/* Header */}
			<div className="Stories-header"><span>{storiesText && renderMarkdown(storiesText.default)}</span></div>

			{/* Body */}
			<div className="Stories-body" id="Stories-body" ref={refele} onMouseDown={(e) => { mouseDownHandler(e); }}>
				{/* Loop over chapters */}
				{chapterData.map((chapter, chapterIndex) => {
					return (
						<div key={chapterIndex} className="Stories-part">
							{/* Chapter header */}
							<div className="Stories-partHeader">
								<span>{chapter.title}</span>
							</div>
							{/* Chapter body */}
							<div className="Stories-partBody">
								{/* Loop over themes */}
								{chapter.themes.map((theme, themeIndex) => {
									return (
									/* Loop over activities */
										theme.activities.map((activity, activityIndex) => {
											/* Activity */
											if (activity.storyFile) {
												return (
													<div 
														key={activityIndex} 
														className="Stories-activity"
														onClick={() => {
															if (isDragging) return;
															toggleStory(chapterIndex, themeIndex, activityIndex);
														}}
													>
														<img src={require('../../assets/images/backgrounds-story/' 
														+ activity.storyImg)} 
														alt="img for story"/>
														<div className="Stories-activityText">
															<span>{activity.storyTitle}</span>
														</div>
													</div>											
												);
											}
											return null;
										})
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
			<div className='Stories-downloadBtn' onClick={(e) => { togleDownloadPopup(e); }}/>

			{showDownloadPopup && <div className='Stories-downloadPopup' onClick={(e) => {
				setShowDownloadPopup(false);
				e.stopPropagation();
			}}>
				<div className='Stories-downloadPopupContentWrapper'
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					{AudioData.map((audio, index) => {
						return <div 
							className={'Stories-downloadChapter' +
							(loadingIndex === index ? ' Stories-downloadChapter-loading' : '') +
							(loadingIndex !== null ? ' isDownloading' : '')} 
							key={index} onClick={(e) => {
								e.stopPropagation();
								handleDownloadAudio(e, audio.id, index); 
							}}>
							<span>{generalUiTexts.chapter + ' ' + (index + 1)}</span>
							
						</div>;
					})}
					<div className= {'Stories-downloadWholeStory Stories-downloadChapter' +
					(loadingIndex === 11 ? ' Stories-downloadChapter-loading' : '') +
					(loadingIndex !== null ? ' isDownloading' : '')}
					
					onClick={(e) => {
						e.stopPropagation();
						handleDownloadAudio(e, 'wholeStory.mp3', 11); 
					}} >
						<span>{generalUiTexts.wholestory}</span>
					</div>

					<div className="Stories-closeBtn" onClick={() => {
						setShowDownloadPopup(false);
					}}></div>
				</div>
			</div>
			}
		</div>
	);
};

Stories.propTypes = {
	selectPage: PropTypes.func.isRequired,
	toggleStory: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired,
};

export default Stories;