let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let gameUrl = 'localhost:3000';
let apiURL = 'http://localhost:8080/api/';


if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-hunter-kids2-test.web.app';
	apiURL = 'https://cgl-hunter-kids2-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-hunter-kids2-demo.web.app';
		apiURL = 'https://cgl-hunter-kids2-demo.web.app/api/';
	}
}

if (env === 'production') {
	gameUrl = 'cgl-hunter-kids2-production.web.app';
	apiURL = 'https://cgl-hunter-kids2-production.web.app/api/';
}

const appConfig = {
	env: env,
	name: 'hunter-kids',
	gameUrl: gameUrl,
	apiURL: apiURL,
	cookieConsentName: 'cgl_hunterkids2_cookies',
	cookieGameProgressName: 'cgl_hunterkids2_gameprogress'
};

export default appConfig;