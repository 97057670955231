import React, {useState, useEffect} from 'react';
import './chapter-five.scss';

const chapterFive = () => {

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [animateSquirrel, setAnimateSquirrel] = useState(true);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {

		const interval = setInterval(() => {setAnimateSquirrel(!animateSquirrel);}, 
			(Math.floor(Math.random() * 25) + 10) * 1000 );

		return () => {
			clearInterval(interval);
		};
	}, [animateSquirrel]);


	return (
		<div className="chapterFive"> 
			<div className='chapterFive-background'/>
			<div className={'chapterFive-squirrel ' + (animateSquirrel ? 'chapterFive-squirrel--animate' : '')}	/>
			<React.Fragment>
				<div className="chapterFive-sunbeam chapterFive-sunbeam--1" />
				<div className="chapterFive-sunbeam chapterFive-sunbeam--2" />
				<div className="chapterFive-sunbeam chapterFive-sunbeam--3" />
				<div className="chapterFive-sunbeam chapterFive-sunbeam--4" />
				<div className="chapterFive-sunbeam chapterFive-sunbeam--5" />
				<div className="chapterFive-sunbeam chapterFive-sunbeam--6" />
			</React.Fragment>
			<div className="chapterFive-dust">	
				<div className="chapterFive-dustParticle chapterFive-dustParticle--1" />
				<div className="chapterFive-dustParticle chapterFive-dustParticle--2" />
			</div>

			<div className='chapterFive-foreground'/>
		</div>
	);
};


export default chapterFive;