import React from 'react';
import PropTypes from 'prop-types';
import badgesData from 'data/badges-data';
import {generalUiTexts} from 'data/ui-texts';
import popupBadgesInfoText from 'data/pages/info-badges.md';
import BackButton from 'components/ui/button/back-button';
import Badge from '../ui/badge/badge';
import './badges.scss';

const Badges = (props) => {
	let {
		prevPage, 
		initialSelectedBadgeIds,
		selectedBadgeIds, 
		selectPage, 
		toggleBadges, 
		toggleBadgesSelector, 
		openPopup
	} = props;

	return (
		<div className={
			'Badges' + (prevPage === 'configure' ? ' Badges--configure' : '')}
		>

			{/* Rewards */}
			{(initialSelectedBadgeIds.indexOf('hunt') < 0 && selectedBadgeIds.indexOf('hunt') >= 0) && 
				<div className="Badges-huntGlow" />}
			{selectedBadgeIds.indexOf('hunt') >= 0 && <div className="Badges-hunt" />}

			
			
			{(initialSelectedBadgeIds.indexOf('fishing') < 0 && selectedBadgeIds.indexOf('fishing') >= 0) && 
				<div className="Badges-fishingGlow" />}
			{selectedBadgeIds.indexOf('fishing') >= 0 && <div className="Badges-fishing" />}

			
			{/* <div className={'Badges-liv' + 
				(selectedBadgeIds.indexOf('fire') >= 0 ? ' Badges-liv--fire' : '')} 
			/>
			
			<div className={'Badges-ask' + (selectedBadgeIds.indexOf('prey') >= 0 
				? ' Badges-ask--prey' 
				: (selectedBadgeIds.indexOf('weapons') >= 0 ? ' Badges-ask--weapons' : ''))}
			/> */}

			
			

			
			{(initialSelectedBadgeIds.indexOf('fire') < 0 && selectedBadgeIds.indexOf('fire') >= 0) && 
				<div className="Badges-fireGlow" />}
			{selectedBadgeIds.indexOf('fire') >= 0 && <div className="Badges-fire" />}

			{(initialSelectedBadgeIds.indexOf('stretcher') < 0 && selectedBadgeIds.indexOf('stretcher') >= 0) && 
				<div className="Badges-stretcherGlow" />}
			{selectedBadgeIds.indexOf('stretcher') >= 0 && <div className="Badges-stretcher" />}
			
			{(initialSelectedBadgeIds.indexOf('music') < 0 && selectedBadgeIds.indexOf('music') >= 0) && 
				<div className="Badges-musicGlow" />}
			{selectedBadgeIds.indexOf('music') >= 0 && <div className="Badges-music" />}
			



			<div className="Badges-foreground" />
			

			{/* Badges */}
			<div className="Badges-wrapper">
				{badgesData.map((badge) => {
					let isSelected = (selectedBadgeIds.indexOf(badge.id) >= 0);
					let animate = (isSelected && initialSelectedBadgeIds.indexOf(badge.id) < 0);
					return (
						<Badge
							key = {badge.id}
							isSelected = {isSelected}
							animate = {animate}
							badgeId = {badge.id}
							toggleBadgesSelector = {toggleBadgesSelector}
						/>
					);
				})}
			</div>
			


			{/* Back button */}
			<BackButton 
				color = "white"
				onClick={() => {
					(prevPage === 'configure' && toggleBadges ? toggleBadges(false) : selectPage(prevPage));
				}}
			/>

			{/* Info popup button */}
			<div 
				className="Badges-infoIcon"
				onClick = {() => {openPopup('infoPopup', null, popupBadgesInfoText, [], null, true);}}
			/>
			{/* Title */}
			<div className="Badges-title">{generalUiTexts.badges}</div>
		</div>
	
	);
};

Badges.propTypes = {
	prevPage: PropTypes.string.isRequired,
	initialSelectedBadgeIds: PropTypes.array.isRequired,
	selectedBadgeIds: PropTypes.array.isRequired,
	selectPage: PropTypes.func.isRequired,
	toggleBadgesSelector: PropTypes.func.isRequired,
	toggleBadges: PropTypes.func,
	openPopup: PropTypes.func.isRequired
};

export default Badges;