import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import Button from 'components/ui/button/button';

import './landing-page.scss';

const LandingPage = ({selectPage, resetCache}) => {
	return (
		<div className="LandingPage">
			<div className="LandingPage-content" onClick={() => {selectPage('chapterOverview');}}>
				<div className="LandingPage-askAndLiv" />
				<div className="LandingPage-title" />
			</div>
			
			{(appConfig.env === 'development' || appConfig.env === 'test') &&
				<Button class="reset" text="Reset" onClick={() => {resetCache();}} />} 

			{/* logos */}
			<div className='LandingPage-nordea'/>
			<div className='LandingPage-naturligvis'/>
		
		</div>
	);
};

LandingPage.propTypes = {
	selectPage: PropTypes.func.isRequired,
	resetCache: PropTypes.func.isRequired,
};

export default LandingPage;