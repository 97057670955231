import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { generalUiTexts } from 'data/ui-texts';
import popupInfoText from 'data/pages/info.md';
import BackButton from 'components/ui/button/back-button';
import ActivityPopup from './activity-popup';
import './chapter-configure.scss';

const ChapterConfigure = (props) => {
	const {
		animateMaterialsIcon,
		chapterIndex,
		chapter, 
		selectedActivityIds,
		selectedActivity,
		openPopup,
		selectPage, 
		handleAnimateMaterialsIcon,
		toggleActivityDescription, 
		toggleActivityStory,
		toggleActivity,
		toggleActivityPopup,
		toggleActivityStoryWithActivityId
	} = props;


	const partHeader = [generalUiTexts.before, generalUiTexts.during, generalUiTexts.after];

	/**
	 * scroll to first activity into view when chapter changes
	 */
	useEffect(()=>{
		const activityContainerOne = document.getElementById('ChapterConfigure-partBody-0');
		const activityContainerTwo = document.getElementById('ChapterConfigure-partBody-1');
		if (activityContainerOne) {
			activityContainerOne.scrollTo({ top: 0, behavior: 'smooth'});
		}
		if (activityContainerTwo) {
			activityContainerTwo.scrollTo({ top: 0, behavior: 'smooth'});
		}
	}, [chapterIndex]);

	return (
		<div className="ChapterConfigure">
			{/* Back button */}
			<BackButton onClick={() => {selectPage('chapterOverview');}}/>

			{/* Page header */}
			<div className="ChapterConfigure-header">
				{/* Chapter menu */}
				<div className='ChapterConfigure-menu'>
					<div className='ChapterConfigure-menuTitle'>
						<span>{generalUiTexts.part + ' ' + (chapterIndex + 1)}</span>
					</div>					
					<div className="ChapterConfigure-menuItems"> 
						{[...Array(5)].map((_, index) => {
							return (
								<div 
									key={index} 
									className={'ChapterConfigure-menuItem ChapterConfigure-menuItem--' 
										+ (index + 1) +
										(chapterIndex === index ? ' ChapterConfigure-menuItem--selected' : '')}
									onClick = {() => {selectPage('chapterConfigure', index);}}
								/>
							);
						})}
					</div>
				</div>

				{/* Material list link */}
				<div className="ChapterConfigure-materials"
					onClick = {() => {selectPage('materialList', chapterIndex);}}>
					<div className={'ChapterConfigure-materialsIcon' + (animateMaterialsIcon ? ' animate' : '')}></div>
				</div>

				{/* Info popup button */}
				<div 
					className="ChapterConfigure-infoIcon"
					onClick = {() => {openPopup('infoPopup', null, popupInfoText, [], null, true);}}
				/>
			</div>

			{/* Page body */}
			<div className="ChapterConfigure-body">
				{chapter.themes.map((theme, themeIndex) => {
					return (
						<div key={themeIndex} className="ChapterConfigure-part">	
							<div className="ChapterConfigure-partHeader"><span>{partHeader[themeIndex]}</span></div>
							<div className='ChapterConfigure-partBodyWrapper'>
								<div className="ChapterConfigure-partBody" 
									id={'ChapterConfigure-partBody-' + themeIndex}>
									{theme.activities.map((activity, activityIndex) => {
										let isSelected = selectedActivityIds.indexOf(activity.id) >= 0;
										// last col 'Historien'
										if (activity.storyFile) {
											return ( 
												<div
													
													key={activityIndex} 
													className={'ChapterConfigure-activity ' +
													'ChapterConfigure-activity--story'}
													onClick={() => {
														toggleActivityStoryWithActivityId(true, activity);
													}}
												>	
													<img src={require('../../assets/images/backgrounds-story/' 
													+ activity.storyImg)}
													alt="img for story"/>
													<div className='ChapterConfigure-textWrapper'>
														<div className="ChapterConfigure-storyChapter">
															<span>{activity.chapter}</span>
														</div>
														<div className="ChapterConfigure-storyTitle">
															<span>{activity.storyTitle}</span>
														</div>
													</div>
												</div>
											);
										}	

										return ( 
											<div 
												key={activityIndex} 
												className={'ChapterConfigure-activity' + 
												(isSelected ? ' ChapterConfigure-activity--selected' : '')
												}
												onClick={() => {toggleActivityPopup(activity.id);}}
											>
												<div className="ChapterConfigure-activityChapter">
													<span>{activity.chapter}</span>
												</div>
													
												<div className="ChapterConfigure-activityTitle">
													<span>{activity.title}</span>
												</div>
												<div className={'ChapterConfigure-activityDescription'}>
													<span>{activity.description}</span>
												</div>
												<div className={'ChapterConfigure-activityCompetenceWrapper'}>
													<div className='ChapterConfigure-activityCompetenceRow'>
														{activity.competences.map((competence, index) => {
															return <div 
																key={index}
																className='ChapterConfigure-activityCompetenceItem'>
																<span>{competence}</span>
															</div>;
														})}
													</div>
													
												</div>
												<div 
													className={'ChapterConfigure-activityStatus' + (isSelected 
														? ' ChapterConfigure-activityStatus--selected' 
														: '')
													}
													onClick={(event) => {
														event.stopPropagation(); 
														handleAnimateMaterialsIcon(!isSelected);
														toggleActivity(activity.id);
													}}
												/>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					);
				})}
			</div>
			<ActivityPopup 
				isOpen={selectedActivity !== null}
				activity={selectedActivity}
				selectedActivityIds={selectedActivityIds}
				toggleActivity={toggleActivity}
				handleAnimateMaterialsIcon={handleAnimateMaterialsIcon}
				toggleActivityDescription={toggleActivityDescription}
				toggleActivityStory = {toggleActivityStory}
				closePopup={toggleActivityPopup}
			/>

		</div>
	);
};

ChapterConfigure.propTypes = {
	animateMaterialsIcon: PropTypes.bool.isRequired,
	selectPage: PropTypes.func.isRequired,
	chapter: PropTypes.object.isRequired,
	selectedActivityIds: PropTypes.array.isRequired,
	selectedActivity: PropTypes.object,
	handleAnimateMaterialsIcon: PropTypes.func.isRequired,
	toggleActivity: PropTypes.func.isRequired,
	toggleActivityDescription: PropTypes.func.isRequired,
	toggleActivityStory: PropTypes.func.isRequired,
	chapterIndex: PropTypes.number.isRequired,
	openPopup: PropTypes.func.isRequired,
	toggleActivityPopup: PropTypes.func.isRequired,
	toggleActivityStoryWithActivityId: PropTypes.func.isRequired,
};

export default ChapterConfigure;