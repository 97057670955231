import React from 'react';
import PropTypes from 'prop-types';
import { renderMarkdown } from 'helpers/text-helper';
import { handlePrintActivities } from 'helpers/print-helper';
import { generalUiTexts, popUpUiTexts } from 'data/ui-texts';
import BackButton from 'components/ui/button/back-button';
import BadgeButton from 'components/ui/button/badge-button';
import StoryButton from 'components/ui/button/story-button';
import PrintButton from 'components/ui/button/print-button';
import './activity-description.scss';
import popupInfoText from 'data/pages/info.md';


const ActivityDescription = (props) => {
	let {
		chapters,
		selectedActivity,
		selectedActivityIds,
		selectedBadgeIds,
		toggleActivityDescription,
		toggleActivityStory,
		toggleBadges,
		openPopup
	} = props;
	
	let badgeIsSelected = (selectedBadgeIds.indexOf(selectedActivity.badgeId) >= 0);

	let allSelectedActivities = [];
	chapters.forEach((chapter) => {
		chapter.themes.forEach((theme) => {
			theme.activities.forEach((chapterActivity) => {
				if (selectedActivityIds.indexOf(chapterActivity.id) >= 0) {
					allSelectedActivities.push(chapterActivity);
				}
			});
		});
	});


	let illustration = null;
	if (selectedActivity.hasOwnProperty('illustration') && selectedActivity.illustration.length > 0) {
		illustration = require('assets/images/illustrations/' + selectedActivity.illustration);
	}


	let popupTextLinks = [];


	return (
		<div className="ActivityDescription">
			{/* Close activity description */}
			<BackButton  onClick={() => {toggleActivityDescription(false);}}/>
			{/* Info popup button */}
			<div 
				className="ActivityDescription-infoIcon"
				onClick = {() => {openPopup('infoPopup', null, popupInfoText, popupTextLinks, null, true);}}
			/>
				
			<div className='ActivityDescription-contentWraper'>
				<div className="ActivityDescription-header">
					<div className="ActivityDescription-title"><span>{selectedActivity.title}</span></div>

					<div className="ActivityDescription-icons">
						{/* Story button */}
						{selectedActivity.storyFile && 
						<StoryButton 
							page="activityDescription"
							onClick = {() => {toggleActivityStory(true);}}
						/>
						}
						{/* Badge button */}
						{selectedActivity.badgeId && 
						<BadgeButton
							page="activityDescription"
							isSelected={badgeIsSelected}
							badgeId={selectedActivity.badgeId}
							onClick={() => {toggleBadges(true);}}
						/>
						}

						{/* Print button */}
						<PrintButton 
							page="activityDescription" 
							onClick = {() => {
								openPopup(
									'printActivities', null, null, [],
									[
										{
											class: 'printActivity', 
											text: popUpUiTexts.printActivity, 
											action: handlePrintActivities, 
											actionParams: [[selectedActivity]]
										},
										{
											class: 'printPlan', 
											text: popUpUiTexts.printPlan, 
											action: handlePrintActivities, 
											actionParams: [allSelectedActivities]
										}
									],
									true, 
									true,
									allSelectedActivities
								);
							}}
						/>
					</div>
				</div>
			
				<div className="ActivityDescription-body">
					{/* Intro box */}
					<div className="ActivityDescription-box">
						<div className="ActivityDescription-tracks" />
						<div className="ActivityDescription-boxText">
							{selectedActivity.hasOwnProperty('descriptionHeader') && 
							renderMarkdown(selectedActivity.descriptionHeader)}
						</div>
					</div>

					{/* Section 1 */}
					<div 
						className="ActivityDescription-sectionContainer talkAbout"
					>
						<div 
							className={'ActivityDescription-sectionHeader'
							}>
								
							{generalUiTexts.activityParts.talkAbout}
							
						</div>
						<div 
							className={'ActivityDescription-sectionBody' + 
							' ActivityDescription-sectionBody--open'
							}>{selectedActivity.hasOwnProperty('descriptionTalkAbout') &&
							renderMarkdown(selectedActivity.descriptionTalkAbout)} </div>
					</div>

					{/* Section 5 */}
					{illustration && <div 
						className="ActivityDescription-sectionContainer" 
					>
						<div 
							className={'ActivityDescription-sectionHeader'
							}></div>
						<div 
							className={'ActivityDescription-sectionBody' + 
							' ActivityDescription-sectionBody--open'
							}>{illustration && <img src={illustration} alt="tegning"/>}
						</div>
					</div>}

					{/* Section 2 */}
					<div 
						className="ActivityDescription-sectionContainer" 
					>
						<div 
							className={'ActivityDescription-sectionHeader'
							}>{generalUiTexts.activityParts.facts}</div>
						<div 
							className={'ActivityDescription-sectionBody' + 
							' ActivityDescription-sectionBody--open'
							}>{selectedActivity.hasOwnProperty('descriptionFacts') && 
							renderMarkdown(selectedActivity.descriptionFacts)}</div>
					</div>

					{/* Section 3 */}
					<div 
						className="ActivityDescription-sectionContainer" 
					>
						<div 
							className={'ActivityDescription-sectionHeader'
							}>{generalUiTexts.activityParts.focusWords}</div>		
						<div 
							className={'ActivityDescription-sectionBody' + 
							' ActivityDescription-sectionBody--open'
							}>{selectedActivity.hasOwnProperty('descriptionFocus') &&
							renderMarkdown(selectedActivity.descriptionFocus)}</div>
					</div>

					{/* Section 4 */}
					<div 
						className="ActivityDescription-sectionContainer" 
					>
						<div 
							className={'ActivityDescription-sectionHeader'
							}>{generalUiTexts.activityParts.materials}</div>
						<div 
							className={'ActivityDescription-sectionBody' + 
							' ActivityDescription-sectionBody--open'
							}>{selectedActivity.hasOwnProperty('materials') && 
							renderMarkdown(selectedActivity.materials)} </div>
					</div>

					{/* Perspective */}
					<div className="ActivityDescription-descriptionReasoning">
						{selectedActivity.hasOwnProperty('descriptionReasoning') && 
						renderMarkdown(selectedActivity.descriptionReasoning)}
					</div>
				</div>
			</div>
		</div>
	);
};


ActivityDescription.propTypes = {
	chapters: PropTypes.array.isRequired,
	selectedActivity: PropTypes.object.isRequired,
	selectedActivityIds: PropTypes.array.isRequired,
	selectedBadgeIds: PropTypes.array.isRequired,
	toggleActivityDescription: PropTypes.func.isRequired,
	toggleActivityStory: PropTypes.func.isRequired,
	toggleBadges: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired
};

export default ActivityDescription;