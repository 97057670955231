import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {generalUiTexts} from 'data/ui-texts';
import PrintComponent from 'components/ui/print-component/print-component';

/**
 * Create the html elements for printing one or more activities and open print window
 * @param {array} activities 
 */
function handlePrintActivities(activities) {
	let html = [];
	activities.forEach((activity, index) => {
		let isLastActivity = (index + 1 === activities.length);
		let content = getActivityDescription(activity, isLastActivity);
		html.push(ReactDOMServer.renderToStaticMarkup(<PrintComponent content={content} />));
	});
	printHelper(html, 'activities');
}

/**
 * Print the material needed for all selected activities
 * @param {array} chapters 
 * @param {array} selectedActivityIds 
 */
function handlePrintMaterial(chapters, selectedActivityIds) {
	let html = [];
	html.push(ReactDOMServer.renderToStaticMarkup(<h1>{generalUiTexts.materiallist}</h1>));
	chapters.forEach((chapter, index) => {
		let selectedActivities = chapter.themes.filter((theme) => {
			return theme.activities.filter((activity) => {
				return selectedActivityIds.indexOf(activity.id) >= 0;
			}).length > 0;
		}).length;
		let content = '<div class="page"><h2>' + generalUiTexts.part + ' ' + (index + 1) + '</h2>\n\n ';
		if (selectedActivities === 0) {
			content = content + '<p>Du har ikke valgt nogen aktiviteter i denne del.</p>';
		} else {
			chapter.themes.forEach((theme) => {
				theme.activities.forEach((activity) => {
					if (selectedActivityIds.indexOf(activity.id) >= 0) {
						content = content + '### ' + activity.title + '\n' + activity.materials + '\n\n';
					}
				});
			});
		}
		content = content + '</div>';
		html.push(ReactDOMServer.renderToStaticMarkup(<PrintComponent content={content} />));
	});
	printHelper(html, 'materials');
}

/**
 * Print single story / all stories
 * @param {array} stories 
 */
function handlePrintStories(stories) {
	let html = [];
	let info = require('data/activities/about.md').default;
	let logo = require('assets/images/logos/logo-blivnaturligvis.png');
	stories.forEach((story, index) => {
		let isLastStory = (index + 1 === stories.length);
		let content = '<div><h2>' + story.title + '</h2>\n\n ' + story.text;
		if (isLastStory) {
			content = content + '<hr>\n\n <p>&nbsp</p><center>' + 
				info + '\n\n <img width="40%" src="' + logo + '" alt="logo"/></center>';
		}
		content = content + '</div>';
		html.push(ReactDOMServer.renderToStaticMarkup(<PrintComponent content={content} />));
	});
	printHelper(html, 'stories');
}

/**
 * Create the full description of an activity
 * @param {object} activity 
 * @param {bool} isLastActivity
 */
function getActivityDescription(activity, isLastActivity = false) {
	let illustration = null;
	if (activity.hasOwnProperty('illustration') && activity.illustration.length > 0) {
		illustration = require('assets/images/illustrations/' + activity.illustration);
	}

	let info = require('data/activities/about.md').default;
	let logo = require('assets/images/logos/logo-blivnaturligvis.png');
	let pageClass = 'page' + (isLastActivity ? ' page--last' : '');
	let activityDescription = '<div class="' + pageClass + '">\n\n # ' + activity.title + '\n\n' + 
		(activity.hasOwnProperty('descriptionHeader') 
			?  activity.descriptionHeader + '\n\n' : '') +
		(activity.hasOwnProperty('descriptionTalkAbout') 
			? '## ' + generalUiTexts.activityParts.talkAbout + '\n' + activity.descriptionTalkAbout + '\n\n' 
			: '') +
		'<hr>\n\n' +
		(activity.hasOwnProperty('illustration') && activity.illustration.length > 0
			? '## ' + generalUiTexts.activityParts.illustrations 
				+ '\n <img width="100%" src="' + illustration + '" alt="illustration" /> \n\n' 
			: '') +
		'<hr>\n\n' +
		(activity.hasOwnProperty('descriptionFacts') 
			? '## ' + generalUiTexts.activityParts.facts + '\n' + activity.descriptionFacts + '\n\n' 
			: '') +
		'<hr>\n\n' +
		(activity.hasOwnProperty('descriptionFocus') 
			? '## ' + generalUiTexts.activityParts.focusWords + '\n' + activity.descriptionFocus + '\n\n' : '') +
		'<hr>\n\n' +
		(activity.hasOwnProperty('materials') 
			? '## ' + generalUiTexts.activityParts.materials + '\n' + activity.materials + '\n\n' : '') +
		'<hr>\n\n' +
		(activity.hasOwnProperty('descriptionReasoning') 
			? activity.descriptionReasoning : '') + '\n\n' +
		'<hr>\n\n' +
		'<p>&nbsp</p><center>' + info + '\n\n <img width="40%" src="' + logo + '" alt="logo"/></center>' +
		'</div>';

	return activityDescription;
}

/**
 * Opens new tab with print content and triggers the print window
 * @param {string} html 
 * @param {string} type 
 * @param {bool} pageBreak
 */
function printHelper(html, type) {
	let documentTitle = generalUiTexts.printTitle;

	var myWindow = window.open();
	myWindow.document.write('<html><head>');
	myWindow.document.write('<title>' + documentTitle + '</title>');
	myWindow.document.write('<link href="https://fonts.googleapis.com/css?family=Open+Sans:400,700" rel="stylesheet">');
	myWindow.document.write('<link href="/print.css" rel="stylesheet">');
	myWindow.document.write('</head>');
	myWindow.document.write('<body>');
	myWindow.document.write('<table class="content content--' + type + '">');
	myWindow.document.write('<tbody><tr><td>');
	html.forEach((content) => {myWindow.document.write(content);});
	myWindow.document.write('</td></tr>');
	myWindow.document.write('<tfoot><tr><td><div class="footer-space"></div></td></tr></tfoot>');
	myWindow.document.write('</table>');
	myWindow.document.write('</body>');
	myWindow.document.write('</html>');
	myWindow.document.close();
	myWindow.focus();
	myWindow.addEventListener('load', () => {setTimeout(() => {myWindow.print();}, 200);});
};

export {handlePrintActivities, handlePrintMaterial, handlePrintStories, getActivityDescription};
