import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {setCookie, getCookie, deleteCookie} from 'helpers/cookie-helper';
// import {isPhoneOrTablet, getOSAndBrowserInfo, getAndroidVersion} from 'helpers/device-helper';
import ImageLoader from 'components/ui/image-loader/image-loader';
import Loading from 'components/loading/loading';
import LandingPage from 'components/landing-page/landing-page';
import ChapterOverview from 'components/chapter-overview/chapter-overview';
import Introduction from 'components/introduction/introduction';
import StoryController from 'components/story/story-controller';
import Resources from 'components/resources/resources';
import About from 'components/about/about';
import ChapterConfigureController from 'components/chapter-configure/chapter-configure-controller';
import BadgesController from 'components/badges/badges-controller';
import MaterialList from 'components/material-list/material-list';
import WholeStory from 'components/story/whole-story';
import CookieConsentController from 'components/ui/cookie-consent/cookie-consent-controller';

/**
 * Game pages object
 */
const gamePages = {
	landingPage: {component: LandingPage},
	chapterOverview: {component: ChapterOverview},
	introduction: {component: Introduction},
	story: {component: StoryController},
	resources: {component: Resources},
	about: {component: About},
	chapterConfigure: {component: ChapterConfigureController},
	badges: {component: BadgesController},
	materialList: {component: MaterialList},
	wholestory: {component: WholeStory}
};

class GameController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			preloadImages: true,
			limitAnimations: true,
			introSeen: false,
			badgesIntroSeen: false,
			page: 'landingPage',
			prevPage: null,
			prevChapterIndex: null,
			chapterIndex: 0,
			selectedActivityIds: [],
			selectedBadgeIds: [],
			cookieConsent: false,
			hasAnsweredConsent: false,
		};
		this.imagesPreloadedSuccessCount = 0;
		this.imagesPreloadedErrorCount = 0;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {


		/* Check if device is too old for all animations */
		let limitAnimations = false;

		// if (!isPhoneOrTablet()) {
		// 	limitAnimations = false;
		// } else {
		// 	let [os, ] = getOSAndBrowserInfo();
		// 	let isIOS = (os === 'iOS' || os === 'Mac OS');	
		// 	if (isIOS) {
		// 		if ('serviceWorker' in navigator) limitAnimations = false;
		// 	} else {
		// 		if (getAndroidVersion() >= 8) limitAnimations = false;
		// 	}
		// }


		/* Get game progress from cache */
		let selectedBadgeIds = [];
		let selectedActivityIds = [];
		let introSeen = false;
		let badgesIntroSeen = false;

		this.loadCookieConsent().then((response) => {
			if (response.status === 'ok' && response.accepted === true) {
				this.loadUserDataCache().then((response) => {
					if (response.status === 'ok') {
						if (response.hasOwnProperty('introSeen') && response.introSeen !== null) {
							introSeen = response.introSeen;
						}
						if (response.hasOwnProperty('badgesIntroSeen') && response.badgesIntroSeen !== null) {
							badgesIntroSeen = response.badgesIntroSeen;
						}
						if (
							response.hasOwnProperty('selectedActivityIds') && 
								typeof response.selectedActivityIds === 'object'
						) selectedActivityIds = response.selectedActivityIds;
						if (
							response.hasOwnProperty('selectedBadgeIds') && 
								typeof response.selectedBadgeIds === 'object'
						) selectedBadgeIds = response.selectedBadgeIds;
					}
		
					this.setState({
						isLoading: false, 
						limitAnimations: limitAnimations,
						introSeen: introSeen,
						badgesIntroSeen: badgesIntroSeen,
						selectedActivityIds: selectedActivityIds,
						selectedBadgeIds: selectedBadgeIds,
						cookieConsent: true, 
						hasAnsweredConsent: true,
					});
						
				}).catch((error) => {
					console.error(error);
					this.setState({isLoading: false});
				});
			} else {
				this.setState({
					isLoading: false, 
					limitAnimations: limitAnimations,
					introSeen: introSeen,
					badgesIntroSeen: badgesIntroSeen,
					selectedActivityIds: selectedActivityIds,
					selectedBadgeIds: selectedBadgeIds,
					cookieConsent: false, 
					hasAnsweredConsent: false,
				});
			}
		});		
	};

	
	/**
	 * Load cookie consent from cache / cookie
	 */
	loadCookieConsent = () => {
		return new Promise((resolve) => {
			/* Use standard cookie */
			let cookie = getCookie(appConfig.cookieConsentName);
			let accepted = false;
			if (cookie.length > 0 && cookie === 'ok') accepted = true;
			resolve({status: 'ok', accepted: accepted});
		});
	};


	/**
	 * Load game progress from cookie
	 */
	loadUserDataCache = () => {
		return new Promise((resolve) => {
			let introSeen = null;
			let badgesIntroSeen = null;
			let selectedActivityIds = [];
			let selectedBadgeIds = [];
			
			let cookieContentString = getCookie(appConfig.cookieGameProgressName);
			if (cookieContentString.length > 0) {
				let cookieContentObj = JSON.parse(cookieContentString);
				if (cookieContentObj.hasOwnProperty('introSeen')) introSeen = cookieContentObj.introSeen;
				if (cookieContentObj.hasOwnProperty('badgesIntroSeen')) {
					badgesIntroSeen = cookieContentObj.badgesIntroSeen;
				}
				if (cookieContentObj.hasOwnProperty('selectedActivityIds')) {
					selectedActivityIds = cookieContentObj.selectedActivityIds;
				}
				if (cookieContentObj.hasOwnProperty('selectedBadgeIds')) {
					selectedBadgeIds = cookieContentObj.selectedBadgeIds;
				}
			}
			resolve({
				status: 'ok',
				introSeen,
				badgesIntroSeen,
				selectedActivityIds,
				selectedBadgeIds
			});
			
		});
	};

	/**
	 * Go to a specific game page
	 * @param {string} page  					id of page, corresponds to property in object gamePages
	 * @param {number} chapterIndex 	chapter index (optional)
	 */
	selectPage = (page, chapterIndex = null) => {
		let prevPage = this.state.page;
		let prevChapterIndex = this.state.chapterIndex;
		/* When coming from landingPage goto fullscreen */
		if (page === 'chapterOverview' && prevPage === 'landingPage') {this.props.handleFullscreen();}
		this.setState({
			prevPage: prevPage,
			prevChapterIndex: prevChapterIndex,
			page: page, 
			chapterIndex: chapterIndex
		});
	};

	/**
	 * Flag intro as seen & update cache
	 */
	handleIntroSeen = () => {
		this.setState({introSeen: true}, () => {
			this.updateGameProgress();
		});
	};

	/**
	 * Flag badges intro as seen & update cache
	 */
	handleBadgesIntroSeen = () => {
		this.setState({badgesIntroSeen: true}, () => {
			this.updateGameProgress();
		});
	};

	/**
	 * Activate/deactivate a badge & update cache
	 * @param {string} badgeId 
	 */
	toggleBadge = (badgeId) => {
		let selectedBadgeIds = JSON.parse(JSON.stringify(this.state.selectedBadgeIds));
		let badgeIndex = selectedBadgeIds.indexOf(badgeId);
		if (badgeIndex < 0) {
			selectedBadgeIds.push(badgeId);
		} else {
			selectedBadgeIds.splice(badgeIndex, 1);
		}
		this.setState({selectedBadgeIds}, () => {this.updateGameProgress();});
	};

	/**
	 * Select / deselect activity & update cache
	 * @param {string} activityId
	*/
	toggleActivity = (activityId) => {
		let selectedActivityIds = JSON.parse(JSON.stringify(this.state.selectedActivityIds));
		let activityIndex = selectedActivityIds.indexOf(activityId);
		if (activityIndex < 0) {
			selectedActivityIds.push(activityId);
		} else {
			selectedActivityIds.splice(activityIndex, 1);
		}
		this.setState({selectedActivityIds}, () => {this.updateGameProgress();});
	};


	/**
	 * Save game progress in state and in cookie
	 */
	updateGameProgress = () => {
		if (!this.state.cookieConsent) {return;}
		let introSeen = this.state.introSeen;
		let badgesIntroSeen = this.state.badgesIntroSeen;
		let selectedActivityIds = JSON.parse(JSON.stringify(this.state.selectedActivityIds));
		let selectedBadgeIds = JSON.parse(JSON.stringify(this.state.selectedBadgeIds));
		let cookieContentObj = {introSeen, badgesIntroSeen, selectedActivityIds, selectedBadgeIds};
		let cookieContentString = JSON.stringify(cookieContentObj);
		setCookie(appConfig.cookieGameProgressName, cookieContentString, 365);
	};

	/**
	 * Reset cookies
	 */
	resetCache = () => {
		/* Use standard cookie */
		deleteCookie(appConfig.cookieConsentName);
		deleteCookie(appConfig.cookieGameProgressName);
		window.location.reload();
	};

	/**
	 * Accepts or declines cookies depending on the given boolean
	 * @param {bool} cookiesAccepted 
	 */
	setCookiesConsent = (cookiesAccepted) => {
		if (cookiesAccepted) {
			let introSeen = this.state.introSeen;
			let badgesIntroSeen = this.state.badgesIntroSeen;
			let selectedActivityIds = JSON.parse(JSON.stringify(this.state.selectedActivityIds));
			let selectedBadgeIds = JSON.parse(JSON.stringify(this.state.selectedBadgeIds));
			let cookieContentObj = {introSeen, badgesIntroSeen, selectedActivityIds, selectedBadgeIds};
			let cookieContentString = JSON.stringify(cookieContentObj);
			setCookie(appConfig.cookieGameProgressName, cookieContentString, 365);
			setCookie(appConfig.cookieConsentName, 'ok', 365);
		} else {
			if (!this.state.hasAnsweredConsent) {
				this.setState({hasAnsweredConsent: true});
				return new Promise((resolve) => {
					resolve({ status: 'ok' });
				});
			}
			deleteCookie(appConfig.cookieConsentName);
			deleteCookie(appConfig.cookieGameProgressName);
		}
		return new Promise((resolve) => {
			this.setState({
				cookieConsent: cookiesAccepted,
				hasAnsweredConsent: true,
			}, () => { 
				resolve({ status: 'ok' });
			});
		});
	};


	/**
	 * Render Component
	 */
	render = () => {
		/* Get game page component */				
		let Component = LandingPage;
		if (gamePages.hasOwnProperty(this.state.page)) Component = gamePages[this.state.page].component;

		/* Game is loading */
		if ((this.state.isLoading) && this.state.page !== 'landingPage') {
			Component = Loading;
		}

		return (
			<React.Fragment>
				{/* cookie Consent */}
				{(this.state.page === 'chapterOverview' || this.state.page === 'landingPage' ) &&
				<CookieConsentController 
					setCookieConsent={this.setCookiesConsent}
					cookieConsentState={this.state.cookieConsent}
					triggerFirstTimeCookieConsent={!this.state.hasAnsweredConsent}
				/> }
				<Component
					isLoading={this.state.isLoading}
					limitAnimations={this.state.limitAnimations}
					introSeen={this.state.introSeen}
					badgesIntroSeen={this.state.badgesIntroSeen}
					chapterIndex={this.state.chapterIndex}
					prevPage={this.state.prevPage}
					prevChapterIndex={this.state.prevChapterIndex}
					selectedActivityIds={this.state.selectedActivityIds}
					selectedBadgeIds={this.state.selectedBadgeIds}
					selectPage={this.selectPage}
					handleIntroSeen={this.handleIntroSeen}
					handleBadgesIntroSeen={this.handleBadgesIntroSeen}
					toggleActivity={this.toggleActivity}
					toggleBadge={this.toggleBadge}
					resetCache={this.resetCache}
					openPopup={this.props.openPopup}
					closePopup={this.props.closePopup}
					openPopupPrint={this.props.openPopupPrint}
				/>
				{this.state.preloadImages && <ImageLoader handlePreloadImage={this.handlePreloadImage} />}
				
			</React.Fragment>
		);
	};
}

GameController.propTypes = {
	openPopup: PropTypes.func,
	openPopupPrint: PropTypes.func,
	closePopup: PropTypes.func,
	handleFullscreen: PropTypes.func,
};

export default GameController;