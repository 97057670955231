import React from 'react';
import PropTypes from 'prop-types';
import './chapter-nine.scss';

const chapterNine = () => {
	return (
		<div className="chapterNine"> 
			<div className='chapterNine-background'/>
			<div className='chapterNine-clouds'/>
			<div className='chapterNine-waves'/>
			<div className='chapterNine-foreground'/>
			<div className='chapterNine-grass'/>

		</div>
	);
};


chapterNine.propTypes = {
	limitAnimations: PropTypes.bool.isRequired,
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired
};


export default chapterNine;