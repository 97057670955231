import React from 'react';
import PropTypes from 'prop-types';

import './cgl.scss';

const CGL = ({ type, classes }) => {
	let logo = null;
	if (type === 'white') {
		try {
			logo = require('../../assets/images/logos/logo-cgl.svg');
		} catch (e) {
			console.error('Could not find text: assets/images/logos/logo-cgl.svg');
		}
	} else {
		try {
			logo = require('../../assets/images/logos/logo-cgl-dark.svg');
		} catch (e) {
			console.error('Could not find text: assets/images/logos/logo-cgl-dark.svg');
		}
	}
	/* Classes */
	let className = 'CGL';
	if (classes && classes.length > 0) {        
		classes.forEach((c) => {className = className + ' ' + c;});
	}

	return (
		<div className={className}>
			<img className={'CGL-Logo'} src={logo.default} alt="Copenhagen Game Lab logo" onClick={() => {window.open('https://cphgamelab.dk/', '_blank', 'noopener,noreferrer');}}/>
		</div>
	);
};
CGL.defaultProps = {
	type: 'white',
	classes: null
};

CGL.propTypes = {
	type: PropTypes.string,
	classes: PropTypes.array,
};

export default CGL;