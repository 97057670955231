import React, { useState, useEffect } from 'react';
import './chapter-two.scss';

const chapterTwo = () => {

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [animateHare, setAnimateHare] = useState(true);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		// random values betwee 11000-15000
		const interval = setInterval(() => {setAnimateHare(!animateHare);}, 
			(Math.floor(Math.random() * 5) + 11) * 1000 );

		return () => {
			clearInterval(interval);
		};
	}, [animateHare]);


	return (
		<div className="chapterTwo"> 
			<div className='chapterTwo-background'/>

			<div className={'chapterTwo-hare ' + (animateHare ? 'chapterTwo-hare--animate' : '')}/>

			<React.Fragment>
				<div className="chapterTwo-sunbeam chapterTwo-sunbeam--1" />
				<div className="chapterTwo-sunbeam chapterTwo-sunbeam--2" />
				<div className="chapterTwo-sunbeam chapterTwo-sunbeam--3" />
				<div className="chapterTwo-sunbeam chapterTwo-sunbeam--4" />
				<div className="chapterTwo-sunbeam chapterTwo-sunbeam--5" />
				<div className="chapterTwo-sunbeam chapterTwo-sunbeam--6" />
			</React.Fragment>

			<div className="chapterTwo-dust">	
				<div className="chapterTwo-dustParticle chapterTwo-dustParticle--1" />
				<div className="chapterTwo-dustParticle chapterTwo-dustParticle--2" />
			</div>

			<div className='chapterTwo-foreground'/>

		</div>
	);
};


export default chapterTwo;