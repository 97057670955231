const chapterData = [
	/* Del 1 */
	{
		id: 1,
		title: 'Del 1',
		themes: [
			{
			id: 1,
			isIndoor: true,
			activities: [
				{
					id: 'stoneage-clothing',
					title: 'Jægerstenalder-beklædning',
					description: 'Skab jeres egne jægerstenalder-dragter af stof. ',
					synopsis: require('./activities/stoneage-clothing/synopsis.md').default,
					descriptionHeader: require('./activities/stoneage-clothing/description-header.md').default,
					descriptionTalkAbout: require('./activities/stoneage-clothing/description-talk-about.md').default,
					descriptionFacts: require('./activities/stoneage-clothing/description-facts.md').default,
					descriptionFocus: require('./activities/stoneage-clothing/description-focus.md').default,
					descriptionReasoning: require('./activities/stoneage-clothing/description-reasoning.md').default,
					materials: require('./activities/stoneage-clothing/materials.md').default,
					illustration: 'activity-67.png',
					storyFile: null,
					storyNumber: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 1',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'mini-living-space',
					title: 'Mini boplads',
					description: 'Saml naturmaterialer og byg jeres egne miniature-bopladser.',
					synopsis: require('./activities/mini-living-space/synopsis.md').default ,
					descriptionHeader: require('./activities/mini-living-space/description-header.md').default,
					descriptionTalkAbout: require('./activities/mini-living-space/description-talk-about.md').default,
					descriptionFacts: require('./activities/mini-living-space/description-facts.md').default,
					descriptionFocus: require('./activities/mini-living-space/description-focus.md').default,
					descriptionReasoning: require('./activities/mini-living-space/description-reasoning.md').default,
					materials: require('./activities/mini-living-space/materials.md').default,
					illustration: 'activity-57.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 1',
					competences: [
						'Krea/musisk udtryksform', 'Engagement og fællesskab'
					]
				},
				{
					id: 'portrait',
					title: 'Ask & Liv-portræt',
					description: 'Lav skønne portrætter med blade og papir.',
					synopsis: require('./activities/portrait/synopsis.md').default ,
					descriptionHeader: require('./activities/portrait/description-header.md').default,
					descriptionTalkAbout: require('./activities/portrait/description-talk-about.md').default,
					descriptionFacts: require('./activities/portrait/description-facts.md').default,
					descriptionFocus: require('./activities/portrait/description-focus.md').default,
					descriptionReasoning: require('./activities/portrait/description-reasoning.md').default,
					materials: require('./activities/portrait/materials.md').default,
					illustration: 'activity-58.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 1',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{

					id: 'animal-tracks',
					title: 'Klog på dyrespor',
					description: 'Bliv sammen klogere på spor fra dyr.',
					synopsis: require('./activities/animal-tracks/synopsis.md').default,
					descriptionHeader: require('./activities/animal-tracks/description-header.md').default,
					descriptionTalkAbout: require('./activities/animal-tracks/description-talk-about.md').default,
					descriptionFacts: require('./activities/animal-tracks/description-facts.md').default,
					descriptionFocus: require('./activities/animal-tracks/description-focus.md').default,
					descriptionReasoning: require('./activities/animal-tracks/description-reasoning.md').default,
					materials: require('./activities/animal-tracks/materials.md').default,
					illustration: 'activity-63.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 2',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				{
					id: 'glowstone',
					title: 'Glødesten',
					description: 'Mal sten så de ligner flotte gløder.',
					synopsis: require('./activities/glowstone/synopsis.md').default,
					descriptionHeader: require('./activities/glowstone/description-header.md').default,
					descriptionTalkAbout: require('./activities/glowstone/description-talk-about.md').default,
					descriptionFacts: require('./activities/glowstone/description-facts.md').default,
					descriptionFocus: require('./activities/glowstone/description-focus.md').default,
					descriptionReasoning: require('./activities/glowstone/description-reasoning.md').default,
					materials: require('./activities/glowstone/materials.md').default,
					illustration: 'activity-39.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 2',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'stone-age-children',
					title: 'Stenalderbørn',
					description: 'Lav flotte aftegninger af hinanden og pynt med natyrmaterialer.',
					synopsis: require('./activities/stone-age-children/synopsis.md').default,
					descriptionHeader: require('./activities/stone-age-children/description-header.md').default,
					descriptionTalkAbout: require('./activities/stone-age-children/description-talk-about.md').default,
					descriptionFacts: require('./activities/stone-age-children/description-facts.md').default,
					descriptionFocus: require('./activities/stone-age-children/description-focus.md').default,
					descriptionReasoning: require('./activities/stone-age-children/description-reasoning.md').default,
					materials: require('./activities/stone-age-children/materials.md').default,
					illustration: 'activity-25.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 2',
					competences: [
						'Krea/musisk udtryksform','Engagement og fællesskab'
					]
				},
				{
					id: 'stone-age-stomp-and-rhyme-hunting',
					title: 'Stenalder STOMP & Rim - Jagt',
					description: 'Lav en fed rytme og lær et jagt-rim.',
					synopsis: require('./activities/stone-age-stomp-and-rhyme-hunting/synopsis.md').default,
					descriptionHeader: require('./activities/stone-age-stomp-and-rhyme-hunting/description-header.md').default,
					descriptionTalkAbout: require('./activities/stone-age-stomp-and-rhyme-hunting/description-talk-about.md').default,
					descriptionFacts: require('./activities/stone-age-stomp-and-rhyme-hunting/description-facts.md').default,
					descriptionFocus: require('./activities/stone-age-stomp-and-rhyme-hunting/description-focus.md').default,
					descriptionReasoning: require('./activities/stone-age-stomp-and-rhyme-hunting/description-reasoning.md').default,
					materials: require('./activities/stone-age-stomp-and-rhyme-hunting/materials.md').default,
					illustration: 'activity-07.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 1 & 2',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'song-ask-and-liv',
					title: 'Ask & Liv-sangen ”På Vildspor”',
					description: 'Syng med på og bevæg jer til Ask & Liv-sangen.',
					synopsis: require('./activities/song-ask-and-liv/synopsis.md').default,
					descriptionHeader: require('./activities/song-ask-and-liv/description-header.md').default,
					descriptionTalkAbout: require('./activities/song-ask-and-liv/description-talk-about.md').default,
					descriptionFacts: require('./activities/song-ask-and-liv/description-facts.md').default,
					descriptionFocus: require('./activities/song-ask-and-liv/description-focus.md').default,
					descriptionReasoning: require('./activities/song-ask-and-liv/description-reasoning.md').default,
					materials: require('./activities/song-ask-and-liv/materials.md').default,
					illustration: 'activity-49.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 1 & 2',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'massage-history',
					title: 'Stenalder massagehistorie',
					description: 'Styrk de sociale relationer med massage.',
					synopsis: require('./activities/massage-history/synopsis.md').default,
					descriptionHeader: require('./activities/massage-history/description-header.md').default,
					descriptionTalkAbout: require('./activities/massage-history/description-talk-about.md').default,
					descriptionFacts: require('./activities/massage-history/description-facts.md').default,
					descriptionFocus: require('./activities/massage-history/description-focus.md').default,
					descriptionReasoning: require('./activities/massage-history/description-reasoning.md').default,
					materials: require('./activities/massage-history/materials.md').default,
					illustration: 'activity-33.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 1 & 2',
					competences: [
						'Engagement og fællesskab','Krop og bevægelse'
					]
				},
				
				{
					id: 'children-yoga',
					title: 'Børne-yoga i makkerpar',
					description: 'Brug yoga til at flytte fokus fra “mig” til “os”.',
					synopsis: require('./activities/children-yoga/synopsis.md').default,
					descriptionHeader: require('./activities/children-yoga/description-header.md').default,
					descriptionTalkAbout: require('./activities/children-yoga/description-talk-about.md').default,
					descriptionFacts: require('./activities/children-yoga/description-facts.md').default,
					descriptionFocus: require('./activities/children-yoga/description-focus.md').default,
					descriptionReasoning: require('./activities/children-yoga/description-reasoning.md').default,
					materials: require('./activities/children-yoga/materials.md').default,
					illustration: 'activity-09.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 1 & 2',
					competences: [
						'Krop og bevægelse','Engagement og fællesskab'
					]
				},
				{
					id: 'digital-template',
					title: 'Digital bogskabelon med opgaver',
					description: 'Arbejd med små digitale opgaver til hvert kapitel.',
					synopsis: require('./activities/digital-template/synopsis.md').default,
					descriptionHeader: require('./activities/digital-template/description-header.md').default,
					descriptionTalkAbout: require('./activities/digital-template/description-talk-about.md').default,
					descriptionFacts: require('./activities/digital-template/description-facts.md').default,
					descriptionFocus: require('./activities/digital-template/description-focus.md').default,
					descriptionReasoning: require('./activities/digital-template/description-reasoning.md').default,
					materials: require('./activities/digital-template/materials.md').default,
					illustration: 'activity-47.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 1 & 2',
					competences: [
						'Sprog', 'Naturfaglige fænomener'
					]
				},
				{
					id: 'dialog-card',
					title: 'Dialogkort',
					description: 'Tal med børnene om overgang fra børnehave til skole.',
					synopsis: require('./activities/dialog-card/synopsis.md').default,
					descriptionHeader: require('./activities/dialog-card/description-header.md').default,
					descriptionTalkAbout: require('./activities/dialog-card/description-talk-about.md').default,
					descriptionFacts: require('./activities/dialog-card/description-facts.md').default,
					descriptionFocus: require('./activities/dialog-card/description-focus.md').default,
					descriptionReasoning: require('./activities/dialog-card/description-reasoning.md').default,
					materials: require('./activities/dialog-card/materials.md').default,
					illustration: 'activity-06.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 1 & 2',
					competences: [
						'Engagement og fællesskab'
					]
				},
				
			]
		},
		{
			id: 2,
			isIndoor: false,
			activities: [
				{

					id: 'shelf-pearls',
					title: 'Hyldeperler',
					description: 'Lav flotte perler og halskæder af hyld.',
					synopsis: require('./activities/shelf-pearls/synopsis.md').default,
					descriptionHeader: require('./activities/shelf-pearls/description-header.md').default,
					descriptionTalkAbout: require('./activities/shelf-pearls/description-talk-about.md').default,
					descriptionFacts: require('./activities/shelf-pearls/description-facts.md').default,
					descriptionFocus: require('./activities/shelf-pearls/description-focus.md').default,
					descriptionReasoning: require('./activities/shelf-pearls/description-reasoning.md').default,
					materials: require('./activities/shelf-pearls/materials.md').default,
					illustration: 'activity-37.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 1',
					competences: [
						'Naturfaglige fænomener','Krea/musisk udtryksform'
					]
				},
				{
					id: 'build-caves',
					title: 'Byg huler',
					description: 'Byg jeres egen boplads.',
					synopsis: require('./activities/build-caves/synopsis.md').default,
					descriptionHeader: require('./activities/build-caves/description-header.md').default,
					descriptionTalkAbout: require('./activities/build-caves/description-talk-about.md').default,
					descriptionFacts: require('./activities/build-caves/description-facts.md').default,
					descriptionFocus: require('./activities/build-caves/description-focus.md').default,
					descriptionReasoning: require('./activities/build-caves/description-reasoning.md').default,
					materials: require('./activities/build-caves/materials.md').default,
					illustration: 'activity-64.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 1',
					competences: [
						'Engagement og fællesskab','Naturfaglige fænomener'
					]
				},
				{
					id: 'clay-pot',
					title: 'Lerkrukke',
					description: 'Lav jeres egne krukker af ler.',
					synopsis: require('./activities/clay-pot/synopsis.md').default,
					descriptionHeader: require('./activities/clay-pot/description-header.md').default,
					descriptionTalkAbout: require('./activities/clay-pot/description-talk-about.md').default,
					descriptionFacts: require('./activities/clay-pot/description-facts.md').default,
					descriptionFocus: require('./activities/clay-pot/description-focus.md').default,
					descriptionReasoning: require('./activities/clay-pot/description-reasoning.md').default,
					materials: require('./activities/clay-pot/materials.md').default,
					illustration: 'activity-34.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 1',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				
				{

					id: 'hunting-for-animal-tracks' ,
					title: 'Jagt efter dyrespor',
					description: 'Gå på jagt efter dyrespor i naturen.',
					synopsis: require('./activities/hunting-for-animal-tracks/synopsis.md').default,
					descriptionHeader: require('./activities/hunting-for-animal-tracks/description-header.md').default,
					descriptionTalkAbout: require('./activities/hunting-for-animal-tracks/description-talk-about.md').default,
					descriptionFacts: require('./activities/hunting-for-animal-tracks/description-facts.md').default,
					descriptionFocus: require('./activities/hunting-for-animal-tracks/description-focus.md').default,
					descriptionReasoning: require('./activities/hunting-for-animal-tracks/description-reasoning.md').default,
					materials: require('./activities/hunting-for-animal-tracks/materials.md').default,
					illustration: 'activity-65.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 2',
					competences: [
						'Naturfaglige fænomener','Engagement og fællesskab'
					]
				},
				{
					id: 'animal-tracks-amulet' ,
					title: 'Dyrespors-amulet',
					description: 'Lav simple og flotte amuletter.',
					synopsis: require('./activities/animal-tracks-amulet/synopsis.md').default,
					descriptionHeader: require('./activities/animal-tracks-amulet/description-header.md').default,
					descriptionTalkAbout: require('./activities/animal-tracks-amulet/description-talk-about.md').default,
					descriptionFacts: require('./activities/animal-tracks-amulet/description-facts.md').default,
					descriptionFocus: require('./activities/animal-tracks-amulet/description-focus.md').default,
					descriptionReasoning: require('./activities/animal-tracks-amulet/description-reasoning.md').default,
					materials: require('./activities/animal-tracks-amulet/materials.md').default,
					illustration: 'activity-61.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 2',
					competences: [
						'Naturfaglige fænomener','Krea/musisk udtryksform'
					]
				},
				{
					id: 'move-like-a-hunter' ,
					title: 'Bevæg dig som en jæger',
					description: 'Bevæg jer gennem naturen som en ægte jæger.',
					synopsis: require('./activities/move-like-a-hunter/synopsis.md').default,
					descriptionHeader: require('./activities/move-like-a-hunter/description-header.md').default,
					descriptionTalkAbout: require('./activities/move-like-a-hunter/description-talk-about.md').default,
					descriptionFacts: require('./activities/move-like-a-hunter/description-facts.md').default,
					descriptionFocus: require('./activities/move-like-a-hunter/description-focus.md').default,
					descriptionReasoning: require('./activities/move-like-a-hunter/description-reasoning.md').default,
					materials: require('./activities/move-like-a-hunter/materials.md').default,
					illustration: 'activity-62.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 2',
					competences: [
						'Engagement og fællesskab','Krop og bevægelse'
					]
				},
				{
					id: 'going-hunting' ,
					title: 'Gå på jagt',
					description: 'Øv jer i at snige jer ind på “rådyret”.',
					synopsis: require('./activities/going-hunting/synopsis.md').default,
					descriptionHeader: require('./activities/going-hunting/description-header.md').default,
					descriptionTalkAbout: require('./activities/going-hunting/description-talk-about.md').default,
					descriptionFacts: require('./activities/going-hunting/description-facts.md').default,
					descriptionFocus: require('./activities/going-hunting/description-focus.md').default,
					descriptionReasoning: require('./activities/going-hunting/description-reasoning.md').default,
					materials: require('./activities/going-hunting/materials.md').default,
					illustration: 'activity-70.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 2',
					competences: [
						'Naturfaglige fænomener','Engagement og fællesskab'
					]
				},
				
				{
					id: 'move-the-glow-baton' ,
					title: 'Flyt gløden-stafet',
					description: 'Arbejd sammen om at bære en glød til bålet.',
					synopsis: require('./activities/move-the-glow-baton/synopsis.md').default,
					descriptionHeader: require('./activities/move-the-glow-baton/description-header.md').default,
					descriptionTalkAbout: require('./activities/move-the-glow-baton/description-talk-about.md').default,
					descriptionFacts: require('./activities/move-the-glow-baton/description-facts.md').default,
					descriptionFocus: require('./activities/move-the-glow-baton/description-focus.md').default,
					descriptionReasoning: require('./activities/move-the-glow-baton/description-reasoning.md').default,
					materials: require('./activities/move-the-glow-baton/materials.md').default,
					illustration: 'activity-42.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 2',
					competences: [
						'Engagement og fællesskab','Krop og bevægelse'
					]
				},
				{
					id: 'sounds-from-the-past-and-present',
					title: 'Lyde fra fortid og nutid',
					description: 'Rejse tilbage i tiden med lyde fra jægerstenalderen.',
					synopsis: require('./activities/sounds-from-the-past-and-present/synopsis.md').default,
					descriptionHeader: require('./activities/sounds-from-the-past-and-present/description-header.md').default,
					descriptionTalkAbout: require('./activities/sounds-from-the-past-and-present/description-talk-about.md').default,
					descriptionFacts: require('./activities/sounds-from-the-past-and-present/description-facts.md').default,
					descriptionFocus: require('./activities/sounds-from-the-past-and-present/description-focus.md').default,
					descriptionReasoning: require('./activities/sounds-from-the-past-and-present/description-reasoning.md').default,
					materials: require('./activities/sounds-from-the-past-and-present/materials.md').default,
					illustration: 'activity-56.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 1 & 2',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				
				
			]
		},
		{
			id: 3,
			isIndoor: false,
			isStory: true,
			activities: [
				{
					id: 'part1-1',
					title: null,
					description: null,
					synopsis: null,
					descriptionHeader: null,
					descriptionTalkAbout: null,
					descriptionFacts: null,
					descriptionFocus: null,
					descriptionReasoning: null,
					materials: null,
					illustration: null,
					storyFile: require('./activities/story/storyChapterOne.md').default,
					storyNumber: 1,
					storyTitle: 'Rejsen',
					storyImg: 'chapter-1-thumb.png',
					storyDescription: '',
					storyAudio: 'chapter-1.mp3',
					badgeId: 'hunt',
					chapter: 'Kapitel 1',
					competences: null
				},
				{
					id: 'part1-2',
					title: null,
					description: null,
					synopsis: null,
					descriptionHeader: null,
					descriptionTalkAbout: null,
					descriptionFacts: null,
					descriptionFocus: null,
					descriptionReasoning: null,
					materials: null,
					illustration: null,
					storyFile: require('./activities/story/storyChapterTwo.md').default,
					storyNumber: 2,
					storyTitle: 'På jagt',
					storyImg: 'chapter-2-thumb.png',
					storyDescription: 'At tale med ånder og vende jagtlykken.',
					storyAudio: 'chapter-2.mp3',
					badgeId: null,
					chapter: 'Kapitel 2',
					competences: null,
				},
			]
		},
	
	],


	},
	/* Del 2 */
	{
		id: 2,
		title: 'Del 2',
		themes: [
			{
			id: 1,
			isIndoor: true,
			badge: '',
			activities: [
				{

					id: 'stone-age-wallet',
					title: 'Stenalderpung',
					description: 'Lav flotte punge af læder eller stof.',
					synopsis: require('./activities/stone-age-wallet/synopsis.md').default,
					descriptionHeader: require('./activities/stone-age-wallet/description-header.md').default,
					descriptionTalkAbout: require('./activities/stone-age-wallet/description-talk-about.md').default,
					descriptionFacts: require('./activities/stone-age-wallet/description-facts.md').default,
					descriptionFocus: require('./activities/stone-age-wallet/description-focus.md').default,
					descriptionReasoning: require('./activities/stone-age-wallet/description-reasoning.md').default,
					materials: require('./activities/stone-age-wallet/materials.md').default,
					illustration: 'Stenalderpung.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 3',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'krea-tjur',
					title: 'Tjur-fugle',
					description: 'Lav flotte tjur-fugle af paptallerkener.',
					synopsis: require('./activities/krea-tjur/synopsis.md').default,
					descriptionHeader: require('./activities/krea-tjur/description-header.md').default,
					descriptionTalkAbout: require('./activities/krea-tjur/description-talk-about.md').default,
					descriptionFacts: require('./activities/krea-tjur/description-facts.md').default,
					descriptionFocus: require('./activities/krea-tjur/description-focus.md').default,
					descriptionReasoning: require('./activities/krea-tjur/description-reasoning.md').default,
					materials: require('./activities/krea-tjur/materials.md').default,
					illustration: 'activity-27.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 3',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'crown-beasts',
					title: 'Rudel af krondyr',
					description: 'Lav smukke krondyr af pap og pinde.',
					synopsis: require('./activities/crown-beasts/synopsis.md').default,
					descriptionHeader: require('./activities/crown-beasts/description-header.md').default,
					descriptionTalkAbout: require('./activities/crown-beasts/description-talk-about.md').default,
					descriptionFacts: require('./activities/crown-beasts/description-facts.md').default,
					descriptionFocus: require('./activities/crown-beasts/description-focus.md').default,
					descriptionReasoning: require('./activities/crown-beasts/description-reasoning.md').default,
					materials: require('./activities/crown-beasts/materials.md').default,
					illustration: 'activity-10.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 4',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'stone-age-stomp-and-rhyme-hunting',
					title: 'Stenalder STOMP & Rim - Jagt',
					description: 'Lav en fed rytme og lær et jagt-rim.',
					synopsis: require('./activities/stone-age-stomp-and-rhyme-hunting/synopsis.md').default,
					descriptionHeader: require('./activities/stone-age-stomp-and-rhyme-hunting/description-header.md').default,
					descriptionTalkAbout: require('./activities/stone-age-stomp-and-rhyme-hunting/description-talk-about.md').default,
					descriptionFacts: require('./activities/stone-age-stomp-and-rhyme-hunting/description-facts.md').default,
					descriptionFocus: require('./activities/stone-age-stomp-and-rhyme-hunting/description-focus.md').default,
					descriptionReasoning: require('./activities/stone-age-stomp-and-rhyme-hunting/description-reasoning.md').default,
					materials: require('./activities/stone-age-stomp-and-rhyme-hunting/materials.md').default,
					illustration: 'activity-07.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 3 & 4',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'song-ask-and-liv',
					title: 'Ask & Liv-sangen ”På Vildspor”',
					description: 'Syng med på og bevæg jer til Ask & Liv-sangen.',
					synopsis: require('./activities/song-ask-and-liv/synopsis.md').default,
					descriptionHeader: require('./activities/song-ask-and-liv/description-header.md').default,
					descriptionTalkAbout: require('./activities/song-ask-and-liv/description-talk-about.md').default,
					descriptionFacts: require('./activities/song-ask-and-liv/description-facts.md').default,
					descriptionFocus: require('./activities/song-ask-and-liv/description-focus.md').default,
					descriptionReasoning: require('./activities/song-ask-and-liv/description-reasoning.md').default,
					materials: require('./activities/song-ask-and-liv/materials.md').default,
					illustration: 'activity-49.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 3 & 4',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'massage-history',
					title: 'Stenalder massagehistorie',
					description: 'Styrk de sociale relationer med massage.',
					synopsis: require('./activities/massage-history/synopsis.md').default,
					descriptionHeader: require('./activities/massage-history/description-header.md').default,
					descriptionTalkAbout: require('./activities/massage-history/description-talk-about.md').default,
					descriptionFacts: require('./activities/massage-history/description-facts.md').default,
					descriptionFocus: require('./activities/massage-history/description-focus.md').default,
					descriptionReasoning: require('./activities/massage-history/description-reasoning.md').default,
					materials: require('./activities/massage-history/materials.md').default,
					illustration: 'activity-33.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 3 & 4',
					competences: [
						'Engagement og fællesskab','Krop og bevægelse'
					]
				},
				
				{
					id: 'children-yoga',
					title: 'Børne-yoga i makkerpar',
					description: 'Brug yoga til at flytte fokus fra “mig” til “os”.',
					synopsis: require('./activities/children-yoga/synopsis.md').default,
					descriptionHeader: require('./activities/children-yoga/description-header.md').default,
					descriptionTalkAbout: require('./activities/children-yoga/description-talk-about.md').default,
					descriptionFacts: require('./activities/children-yoga/description-facts.md').default,
					descriptionFocus: require('./activities/children-yoga/description-focus.md').default,
					descriptionReasoning: require('./activities/children-yoga/description-reasoning.md').default,
					materials: require('./activities/children-yoga/materials.md').default,
					illustration: 'activity-09.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 3 & 4',
					competences: [
						'Krop og bevægelse','Engagement og fællesskab'
					]
				},
				{
					id: 'digital-template',
					title: 'Digital bogskabelon med opgaver',
					description: 'Arbejd med små digitale opgaver til hvert kapitel.',
					synopsis: require('./activities/digital-template/synopsis.md').default,
					descriptionHeader: require('./activities/digital-template/description-header.md').default,
					descriptionTalkAbout: require('./activities/digital-template/description-talk-about.md').default,
					descriptionFacts: require('./activities/digital-template/description-facts.md').default,
					descriptionFocus: require('./activities/digital-template/description-focus.md').default,
					descriptionReasoning: require('./activities/digital-template/description-reasoning.md').default,
					materials: require('./activities/digital-template/materials.md').default,
					illustration: 'activity-47.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 3 & 4',
					competences: [
						'Sprog', 'Naturfaglige fænomener'
					]
				},
				{
					id: 'dialog-card',
					title: 'Dialogkort',
					description: 'Tal med børnene om overgang fra børnehave til skole.',
					synopsis: require('./activities/dialog-card/synopsis.md').default,
					descriptionHeader: require('./activities/dialog-card/description-header.md').default,
					descriptionTalkAbout: require('./activities/dialog-card/description-talk-about.md').default,
					descriptionFacts: require('./activities/dialog-card/description-facts.md').default,
					descriptionFocus: require('./activities/dialog-card/description-focus.md').default,
					descriptionReasoning: require('./activities/dialog-card/description-reasoning.md').default,
					materials: require('./activities/dialog-card/materials.md').default,
					illustration: 'activity-06.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 3 & 4',
					competences: [
						'Engagement og fællesskab'
					]
				},
			]
		},
		{
			id: 2,
			isIndoor: false,
			activities: [
				{

					id: 'bow-and-arrow',
					title: 'Bue og pil',
					description: 'Lav buer og pile af naturmaterialer.',
					synopsis: require('./activities/bow-and-arrow/synopsis.md').default,
					descriptionHeader: require('./activities/bow-and-arrow/description-header.md').default,
					descriptionTalkAbout: require('./activities/bow-and-arrow/description-talk-about.md').default,
					descriptionFacts: require('./activities/bow-and-arrow/description-facts.md').default,
					descriptionFocus: require('./activities/bow-and-arrow/description-focus.md').default,
					descriptionReasoning: require('./activities/bow-and-arrow/description-reasoning.md').default,
					materials: require('./activities/bow-and-arrow/materials.md').default,
					illustration: 'activity-59.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 3',
					competences: [
						'Naturfaglige fænomener','Krea/musisk udtryksform'
					]
				},
				{
					id: 'los-and-tjur-leg',
					title: 'Los & Tjur-leg',					
					description: 'Prøv om I kan liste jer ind på “tjuren”.',
					synopsis: require('./activities/los-and-tjur-leg/synopsis.md').default,
					descriptionHeader: require('./activities/los-and-tjur-leg/description-header.md').default,
					descriptionTalkAbout: require('./activities/los-and-tjur-leg/description-talk-about.md').default,
					descriptionFacts: require('./activities/los-and-tjur-leg/description-facts.md').default,
					descriptionFocus: require('./activities/los-and-tjur-leg/description-focus.md').default,
					descriptionReasoning: require('./activities/los-and-tjur-leg/description-reasoning.md').default,
					materials: require('./activities/los-and-tjur-leg/materials.md').default,
					illustration: 'activity-35.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 3',
					competences: [
						'Engagement og fællesskab','Krop og bevægelse'
					]
				},
				{
					id: 'drowned-in-nature-larder',
					title: 'Sank i naturens spisekammer',
					description: 'Gå på jagt efter spiselige bær, planter og nødder.',
					synopsis: require('./activities/drowned-in-nature-larder/synopsis.md').default,
					descriptionHeader: require('./activities/drowned-in-nature-larder/description-header.md').default,
					descriptionTalkAbout: require('./activities/drowned-in-nature-larder/description-talk-about.md').default,
					descriptionFacts: require('./activities/drowned-in-nature-larder/description-facts.md').default,
					descriptionFocus: require('./activities/drowned-in-nature-larder/description-focus.md').default,
					descriptionReasoning: require('./activities/drowned-in-nature-larder/description-reasoning.md').default,
					materials: require('./activities/drowned-in-nature-larder/materials.md').default,
					illustration: 'activity-73.png',
					storyFile: null,	
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 3',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				
				{
					id: 'a-taste-of-the-stone-age',
					title: 'Smag på jægerstenalderen',
					description: 'Lav mad over bål som i jægerstenalderen.',
					synopsis: require('./activities/a-taste-of-the-stone-age/synopsis.md').default,
					descriptionHeader: require('./activities/a-taste-of-the-stone-age/description-header.md').default,
					descriptionTalkAbout: require('./activities/a-taste-of-the-stone-age/description-talk-about.md').default,
					descriptionFacts: require('./activities/a-taste-of-the-stone-age/description-facts.md').default,
					descriptionFocus: require('./activities/a-taste-of-the-stone-age/description-focus.md').default,
					descriptionReasoning: require('./activities/a-taste-of-the-stone-age/description-reasoning.md').default,
					materials: require('./activities/a-taste-of-the-stone-age/materials.md').default,
					illustration: 'activity-71.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 3',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				{
					id: 'light-a-fire',
					title: 'Lav bål',
					description: 'Saml optænding og byg et hyggeligt bål. ',
					synopsis: require('./activities/light-a-fire/synopsis.md').default,
					descriptionHeader: require('./activities/light-a-fire/description-header.md').default,
					descriptionTalkAbout: require('./activities/light-a-fire/description-talk-about.md').default,
					descriptionFacts: require('./activities/light-a-fire/description-facts.md').default,
					descriptionFocus: require('./activities/light-a-fire/description-focus.md').default,
					descriptionReasoning: require('./activities/light-a-fire/description-reasoning.md').default,
					materials: require('./activities/light-a-fire/materials.md').default,
					illustration: 'activity-32.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 3',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				{
					id: 'smart-on-game-birds', 
					title: 'Klog på fuglevildt',
					description: 'Undersøg en and eller fasan - både udvendig og indvendig!',
					synopsis: require('./activities/smart-on-game-birds/synopsis.md').default,
					descriptionHeader: require('./activities/smart-on-game-birds/description-header.md').default,
					descriptionTalkAbout: require('./activities/smart-on-game-birds/description-talk-about.md').default,
					descriptionFacts: require('./activities/smart-on-game-birds/description-facts.md').default,
					descriptionFocus: require('./activities/smart-on-game-birds/description-focus.md').default,
					descriptionReasoning: require('./activities/smart-on-game-birds/description-reasoning.md').default,
					materials: require('./activities/smart-on-game-birds/materials.md').default,
					illustration: 'activity-08.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 3',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				{
					id: 'visit-by-bnv', 
					title: 'Besøg af en jæger/fisker',
					description: 'Bliv klogere på natur med en fisker eller jæger.',
					synopsis: require('./activities/visit-by-bnv/synopsis.md').default,
					descriptionHeader: require('./activities/visit-by-bnv/description-header.md').default,
					descriptionTalkAbout: require('./activities/visit-by-bnv/description-talk-about.md').default,
					descriptionFacts: require('./activities/visit-by-bnv/description-facts.md').default,
					descriptionFocus: require('./activities/visit-by-bnv/description-focus.md').default,
					descriptionReasoning: require('./activities/visit-by-bnv/description-reasoning.md').default,
					materials: require('./activities/visit-by-bnv/materials.md').default,
					illustration: 'activity-66.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 3',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				{

					id: 'reindeer-game',
					title: 'Kronhjort-leg',
					description: 'Prøv at være som de store kronhjorte i tre små lege.',
					synopsis: require('./activities/reindeer-game/synopsis.md').default,
					descriptionHeader: require('./activities/reindeer-game/description-header.md').default,
					descriptionTalkAbout: require('./activities/reindeer-game/description-talk-about.md').default,
					descriptionFacts: require('./activities/reindeer-game/description-facts.md').default,
					descriptionFocus: require('./activities/reindeer-game/description-focus.md').default,
					descriptionReasoning: require('./activities/reindeer-game/description-reasoning.md').default,
					materials: require('./activities/reindeer-game/materials.md').default,
					illustration: 'activity-17.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 4',
					competences: [
						'Engagement og fællesskab','Krop og bevægelse'
					]
				},
				{
					id: 'duel-with-the-animals',
					title: 'Dyst med dyrene',					
					description: 'Prøv om I kan løbe, springe, lugte og høre ligesom dyrene.',
					synopsis: require('./activities/duel-with-the-animals/synopsis.md').default,
					descriptionHeader: require('./activities/duel-with-the-animals/description-header.md').default,
					descriptionTalkAbout: require('./activities/duel-with-the-animals/description-talk-about.md').default,
					descriptionFacts: require('./activities/duel-with-the-animals/description-facts.md').default,
					descriptionFocus: require('./activities/duel-with-the-animals/description-focus.md').default,
					descriptionReasoning: require('./activities/duel-with-the-animals/description-reasoning.md').default,
					materials: require('./activities/duel-with-the-animals/materials.md').default,
					illustration: 'activity-45.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 4',
					competences: [
						'Engagement og fællesskab','Krop og bevægelse'
					]
				},
				{
					id: 'color-hunting-in-nature',
					title: 'Farvejagt i naturen',
					description: 'Find forskellige farver i naturen.',
					synopsis: require('./activities/color-hunting-in-nature/synopsis.md').default,
					descriptionHeader: require('./activities/color-hunting-in-nature/description-header.md').default,
					descriptionTalkAbout: require('./activities/color-hunting-in-nature/description-talk-about.md').default,
					descriptionFacts: require('./activities/color-hunting-in-nature/description-facts.md').default,
					descriptionFocus: require('./activities/color-hunting-in-nature/description-focus.md').default,
					descriptionReasoning: require('./activities/color-hunting-in-nature/description-reasoning.md').default,
					materials: require('./activities/color-hunting-in-nature/materials.md').default,
					illustration: 'activity-07.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 4',
					competences: [
						'Naturfaglige fænomener','Engagement og fællesskab'
					]
				},
				
				{
					id: 'sounds-from-the-past-and-present' ,
					title: 'Lyde fra fortid og nutid',
					description: 'Rejse tilbage i tiden med lyde fra jægerstenalderen.',
					synopsis: require('./activities/sounds-from-the-past-and-present/synopsis.md').default,
					descriptionHeader: require('./activities/sounds-from-the-past-and-present/description-header.md').default,
					descriptionTalkAbout: require('./activities/sounds-from-the-past-and-present/description-talk-about.md').default,
					descriptionFacts: require('./activities/sounds-from-the-past-and-present/description-facts.md').default,
					descriptionFocus: require('./activities/sounds-from-the-past-and-present/description-focus.md').default,
					descriptionReasoning: require('./activities/sounds-from-the-past-and-present/description-reasoning.md').default,
					materials: require('./activities/sounds-from-the-past-and-present/materials.md').default,
					illustration: 'activity-56.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 3 & 4',
					competences: [
						'Naturfaglige fænomener'
					]
				},
			]
		},
		{
			id: 3,
			isIndoor: false,
			isStory: true,
			activities: [
				{
					id: 'part2-1',
					title: null,
					description: null,
					synopsis: null,
					descriptionHeader: null,
					descriptionTalkAbout: null,
					descriptionFacts: null,
					descriptionFocus: null,
					descriptionReasoning: null,
					materials: null,
					illustration: null,
					storyFile: require('./activities/story/storyChapterThree.md').default,
					storyNumber: 3,
					storyTitle: 'En ny ven?',
					storyImg: 'chapter-3-thumb.png',
					storyDescription: '',
					storyAudio: 'chapter-3.mp3',
					badgeId: 'fire',
					chapter: 'Kapitel 3',
					competences: [
						'Social', 'Dansk', 'Matematik', 'Koncentration'
					]
				},
				{
					id: 'part2-2',
					title: null,
					description: null,
					synopsis: null,
					descriptionHeader: null,
					descriptionTalkAbout: null,
					descriptionFacts: null,
					descriptionFocus: null,
					descriptionReasoning: null,
					materials: null,
					illustration: null,
					storyFile: require('./activities/story/storyChapterFour.md').default,
					storyNumber: 4,
					storyTitle: 'Fangeleg',
					storyImg: 'chapter-4-thumb.png',
					storyDescription: 'At tale med ånder og vende jagtlykken.',
					storyAudio: 'chapter-4.mp3',
					badgeId: null,
					chapter: 'Kapitel 4',
					competences: [
						'Social', 'Dansk', 'Matematik', 'Koncentration'
					]
				},
			]
		},
	],


	
	},
	/* Del 3 */
	{
		id: 3,
		title: 'Del 3',
		themes: [
			{
			id: 1,
			isIndoor: true,
			badge: '',
			activities: [
				{
					id: 'facial-expressions-and-leaf-hair',
					title: 'Ansigtsudtryk og bladhår',
					description: 'Tegn ansigtsudtryk og lav et vildt hår af blade.',
					synopsis: require('./activities/facial-expressions-and-leaf-hair/synopsis.md').default,
					descriptionHeader: require('./activities/facial-expressions-and-leaf-hair/description-header.md').default,
					descriptionTalkAbout: require('./activities/facial-expressions-and-leaf-hair/description-talk-about.md').default,
					descriptionFacts: require('./activities/facial-expressions-and-leaf-hair/description-facts.md').default,
					descriptionFocus: require('./activities/facial-expressions-and-leaf-hair/description-focus.md').default,
					descriptionReasoning: require('./activities/facial-expressions-and-leaf-hair/description-reasoning.md').default,
					materials: require('./activities/facial-expressions-and-leaf-hair/materials.md').default,
					illustration: 'activity-51.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 5',
					competences: [
						'Engagement og fællesskab','Krea/musisk udtryksform'
					]
				},
				{
					id: 'beautiful-fish',
					title: 'Flotte fisk',
					description: 'Lav flotte fisk til ophæng.',
					synopsis: require('./activities/beautiful-fish/synopsis.md').default,
					descriptionHeader: require('./activities/beautiful-fish/description-header.md').default,
					descriptionTalkAbout: require('./activities/beautiful-fish/description-talk-about.md').default,
					descriptionFacts: require('./activities/beautiful-fish/description-facts.md').default,
					descriptionFocus: require('./activities/beautiful-fish/description-focus.md').default,
					descriptionReasoning: require('./activities/beautiful-fish/description-reasoning.md').default,
					materials: require('./activities/beautiful-fish/materials.md').default,
					illustration: 'activity-41.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 5',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'stone-age-stomp-and-rhyme-hunting',
					title: 'Stenalder STOMP & Rim - Jagt',
					description: 'Lav en fed rytme og lær et jagt-rim.',
					synopsis: require('./activities/stone-age-stomp-and-rhyme-hunting/synopsis.md').default,
					descriptionHeader: require('./activities/stone-age-stomp-and-rhyme-hunting/description-header.md').default,
					descriptionTalkAbout: require('./activities/stone-age-stomp-and-rhyme-hunting/description-talk-about.md').default,
					descriptionFacts: require('./activities/stone-age-stomp-and-rhyme-hunting/description-facts.md').default,
					descriptionFocus: require('./activities/stone-age-stomp-and-rhyme-hunting/description-focus.md').default,
					descriptionReasoning: require('./activities/stone-age-stomp-and-rhyme-hunting/description-reasoning.md').default,
					materials: require('./activities/stone-age-stomp-and-rhyme-hunting/materials.md').default,
					illustration: 'activity-07.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 5',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'facial-expressions-of-modeling-wax',
					title: 'Ansigtsudtryk af modellervoks',
					description: 'Form ansigter og følelser med modellervoks.',
					synopsis: require('./activities/facial-expressions-of-modeling-wax/synopsis.md').default,
					descriptionHeader: require('./activities/facial-expressions-of-modeling-wax/description-header.md').default,
					descriptionTalkAbout: require('./activities/facial-expressions-of-modeling-wax/description-talk-about.md').default,
					descriptionFacts: require('./activities/facial-expressions-of-modeling-wax/description-facts.md').default,
					descriptionFocus: require('./activities/facial-expressions-of-modeling-wax/description-focus.md').default,
					descriptionReasoning: require('./activities/facial-expressions-of-modeling-wax/description-reasoning.md').default,
					materials: require('./activities/facial-expressions-of-modeling-wax/materials.md').default,
					illustration: 'activity-04.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 6',
					competences: [
						'Engagement og fællesskab','Krea/musisk udtryksform'
					]
				},
				{
					id: 'emotional-banking',
					title: 'Følelses-banko',
					description: 'Spil banko med følelser og ansigtsudtryk.',
					synopsis: require('./activities/emotional-banking/synopsis.md').default,
					descriptionHeader: require('./activities/emotional-banking/description-header.md').default,
					descriptionTalkAbout: require('./activities/emotional-banking/description-talk-about.md').default,
					descriptionFacts: require('./activities/emotional-banking/description-facts.md').default,
					descriptionFocus: require('./activities/emotional-banking/description-focus.md').default,
					descriptionReasoning: require('./activities/emotional-banking/description-reasoning.md').default,
					materials: require('./activities/emotional-banking/materials.md').default,
					illustration: 'activity-52.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 6',
					competences: [
						'Engagement og fællesskab'
					]
				},
				{
					id: 'song-ask-and-liv',
					title: 'Ask & Liv-sangen ”På Vildspor”',
					description: 'Syng med på og bevæg jer til Ask & Liv-sangen.',
					synopsis: require('./activities/song-ask-and-liv/synopsis.md').default,
					descriptionHeader: require('./activities/song-ask-and-liv/description-header.md').default,
					descriptionTalkAbout: require('./activities/song-ask-and-liv/description-talk-about.md').default,
					descriptionFacts: require('./activities/song-ask-and-liv/description-facts.md').default,
					descriptionFocus: require('./activities/song-ask-and-liv/description-focus.md').default,
					descriptionReasoning: require('./activities/song-ask-and-liv/description-reasoning.md').default,
					materials: require('./activities/song-ask-and-liv/materials.md').default,
					illustration: 'activity-49.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 5 & 6',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'massage-history',
					title: 'Stenalder massagehistorie',
					description: 'Styrk de sociale relationer med massage.',
					synopsis: require('./activities/massage-history/synopsis.md').default,
					descriptionHeader: require('./activities/massage-history/description-header.md').default,
					descriptionTalkAbout: require('./activities/massage-history/description-talk-about.md').default,
					descriptionFacts: require('./activities/massage-history/description-facts.md').default,
					descriptionFocus: require('./activities/massage-history/description-focus.md').default,
					descriptionReasoning: require('./activities/massage-history/description-reasoning.md').default,
					materials: require('./activities/massage-history/materials.md').default,
					illustration: 'activity-33.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 5 & 6',
					competences: [
						'Engagement og fællesskab','Krop og bevægelse'
					]
				},
				
				{
					id: 'children-yoga',
					title: 'Børne-yoga i makkerpar',
					description: 'Brug yoga til at flytte fokus fra “mig” til “os”.',
					synopsis: require('./activities/children-yoga/synopsis.md').default,
					descriptionHeader: require('./activities/children-yoga/description-header.md').default,
					descriptionTalkAbout: require('./activities/children-yoga/description-talk-about.md').default,
					descriptionFacts: require('./activities/children-yoga/description-facts.md').default,
					descriptionFocus: require('./activities/children-yoga/description-focus.md').default,
					descriptionReasoning: require('./activities/children-yoga/description-reasoning.md').default,
					materials: require('./activities/children-yoga/materials.md').default,
					illustration: 'activity-09.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 5 & 6',
					competences: [
						'Krop og bevægelse','Engagement og fællesskab'
					]
				},
				{
					id: 'digital-template',
					title: 'Digital bogskabelon med opgaver',
					description: 'Arbejd med små digitale opgaver til hvert kapitel.',
					synopsis: require('./activities/digital-template/synopsis.md').default,
					descriptionHeader: require('./activities/digital-template/description-header.md').default,
					descriptionTalkAbout: require('./activities/digital-template/description-talk-about.md').default,
					descriptionFacts: require('./activities/digital-template/description-facts.md').default,
					descriptionFocus: require('./activities/digital-template/description-focus.md').default,
					descriptionReasoning: require('./activities/digital-template/description-reasoning.md').default,
					materials: require('./activities/digital-template/materials.md').default,
					illustration: 'activity-47.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 5 & 6',
					competences: [
						'Sprog', 'Naturfaglige fænomener'
					]
				},
				{
					id: 'dialog-card',
					title: 'Dialogkort',
					description: 'Tal med børnene om overgang fra børnehave til skole.',
					synopsis: require('./activities/dialog-card/synopsis.md').default,
					descriptionHeader: require('./activities/dialog-card/description-header.md').default,
					descriptionTalkAbout: require('./activities/dialog-card/description-talk-about.md').default,
					descriptionFacts: require('./activities/dialog-card/description-facts.md').default,
					descriptionFocus: require('./activities/dialog-card/description-focus.md').default,
					descriptionReasoning: require('./activities/dialog-card/description-reasoning.md').default,
					materials: require('./activities/dialog-card/materials.md').default,
					illustration: 'activity-06.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 5 & 6',
					competences: [
						'Engagement og fællesskab'
					]
				},

			]
		},
		{
			id: 2,
			isIndoor: false,
			activities: [
				{
					id: 'cut-fish-spears',
					title: 'Snit et fiskespyd',
					description: 'Lav jeres egne fiskespyd.',
					synopsis: require('./activities/cut-fish-spears/synopsis.md').default,
					descriptionHeader: require('./activities/cut-fish-spears/description-header.md').default,
					descriptionTalkAbout: require('./activities/cut-fish-spears/description-talk-about.md').default,
					descriptionFacts: require('./activities/cut-fish-spears/description-facts.md').default,
					descriptionFocus: require('./activities/cut-fish-spears/description-focus.md').default,
					descriptionReasoning: require('./activities/cut-fish-spears/description-reasoning.md').default,
					materials: require('./activities/cut-fish-spears/materials.md').default,
					illustration: 'activity-29.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 5',
					competences: [
						'Naturfaglige fænomener','Krea/musisk udtryksform'
					]
				},
				{
					id: 'throw-with-fishing-spear',
					title: 'Kast med fiskespyd',					
					description: 'Kast til måls med fiskespyd.',
					synopsis: require('./activities/throw-with-fishing-spear/synopsis.md').default,
					descriptionHeader: require('./activities/throw-with-fishing-spear/description-header.md').default,
					descriptionTalkAbout: require('./activities/throw-with-fishing-spear/description-talk-about.md').default,
					descriptionFacts: require('./activities/throw-with-fishing-spear/description-facts.md').default,
					descriptionFocus: require('./activities/throw-with-fishing-spear/description-focus.md').default,
					descriptionReasoning: require('./activities/throw-with-fishing-spear/description-reasoning.md').default,
					materials: require('./activities/throw-with-fishing-spear/materials.md').default,
					illustration: 'activity-60.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 5',
					competences: [
						'Krop og bevægelse'
					]
				},
				{
					id: 'circle-off-feelings',
					title: 'Fortællekreds om følelser',
					description: 'Øv jer på at tale om og lytte til følelser.',
					synopsis: require('./activities/circle-off-feelings/synopsis.md').default,
					descriptionHeader: require('./activities/circle-off-feelings/description-header.md').default,
					descriptionTalkAbout: require('./activities/circle-off-feelings/description-talk-about.md').default,
					descriptionFacts: require('./activities/circle-off-feelings/description-facts.md').default,
					descriptionFocus: require('./activities/circle-off-feelings/description-focus.md').default,
					descriptionReasoning: require('./activities/circle-off-feelings/description-reasoning.md').default,
					materials: require('./activities/circle-off-feelings/materials.md').default,
					illustration: 'activity-02.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 5',
					competences: [
						'Engagement og fællesskab'
					]
				},
				{
					id: 'in-search-of-fish-food',
					title: 'På jagt efter fiskens føde',
					description: 'Gå på jagt efter vandets mange smådyr.',
					synopsis: require('./activities/in-search-of-fish-food/synopsis.md').default,
					descriptionHeader: require('./activities/in-search-of-fish-food/description-header.md').default,
					descriptionTalkAbout: require('./activities/in-search-of-fish-food/description-talk-about.md').default,
					descriptionFacts: require('./activities/in-search-of-fish-food/description-facts.md').default,
					descriptionFocus: require('./activities/in-search-of-fish-food/description-focus.md').default,
					descriptionReasoning: require('./activities/in-search-of-fish-food/description-reasoning.md').default,
					materials: require('./activities/in-search-of-fish-food/materials.md').default,
					illustration: 'activity-30.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 5',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				{
					id: 'mini-food-chain',
					title: 'Mini-fødekæde',
					description: 'Bliv klogere på, hvem der spiser hvem.',
					synopsis: require('./activities/mini-food-chain/synopsis.md').default,
					descriptionHeader: require('./activities/mini-food-chain/description-header.md').default,
					descriptionTalkAbout: require('./activities/mini-food-chain/description-talk-about.md').default,
					descriptionFacts: require('./activities/mini-food-chain/description-facts.md').default,
					descriptionFocus: require('./activities/mini-food-chain/description-focus.md').default,
					descriptionReasoning: require('./activities/mini-food-chain/description-reasoning.md').default,
					materials: require('./activities/mini-food-chain/materials.md').default,
					illustration: 'activity-55.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 5',
					competences: [
						'Naturfaglige fænomener'
					]
				},
					
				{
					id: 'smart-on-fish',
					title: 'Klog på fisk',
					description: 'Undersøg en fisk både udvendig og indvendig.',
					synopsis: require('./activities/smart-on-fish/synopsis.md').default,
					descriptionHeader: require('./activities/smart-on-fish/description-header.md').default,
					descriptionTalkAbout: require('./activities/smart-on-fish/description-talk-about.md').default,
					descriptionFacts: require('./activities/smart-on-fish/description-facts.md').default,
					descriptionFocus: require('./activities/smart-on-fish/description-focus.md').default,
					descriptionReasoning: require('./activities/smart-on-fish/description-reasoning.md').default,
					materials: require('./activities/smart-on-fish/materials.md').default,
					illustration: 'activity-38.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 6',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				{
					id: 'light-a-fire',
					title: 'Lav bål',
					description: 'Saml optænding og byg et hyggeligt bål. ',
					synopsis: require('./activities/light-a-fire/synopsis.md').default,
					descriptionHeader: require('./activities/light-a-fire/description-header.md').default,
					descriptionTalkAbout: require('./activities/light-a-fire/description-talk-about.md').default,
					descriptionFacts: require('./activities/light-a-fire/description-facts.md').default,
					descriptionFocus: require('./activities/light-a-fire/description-focus.md').default,
					descriptionReasoning: require('./activities/light-a-fire/description-reasoning.md').default,
					materials: require('./activities/light-a-fire/materials.md').default,
					illustration: 'activity-32.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 6',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				
				{
					id: 'fish-on-a-stick',
					title: 'Helstegt fisk på pind',
					description: 'Lav lækker fisk over bål.',
					synopsis: require('./activities/fish-on-a-stick/synopsis.md').default,
					descriptionHeader: require('./activities/fish-on-a-stick/description-header.md').default,
					descriptionTalkAbout: require('./activities/fish-on-a-stick/description-talk-about.md').default,
					descriptionFacts: require('./activities/fish-on-a-stick/description-facts.md').default,
					descriptionFocus: require('./activities/fish-on-a-stick/description-focus.md').default,
					descriptionReasoning: require('./activities/fish-on-a-stick/description-reasoning.md').default,
					materials: require('./activities/fish-on-a-stick/materials.md').default,
					illustration: 'activity-40.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 6',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				{
					id: 'fishing-pressure',
					title: 'Fisketryk',
					description: 'Lav farvetryk med ægte fisk!',
					synopsis: require('./activities/fishing-pressure/synopsis.md').default,
					descriptionHeader: require('./activities/fishing-pressure/description-header.md').default,
					descriptionTalkAbout: require('./activities/fishing-pressure/description-talk-about.md').default,
					descriptionFacts: require('./activities/fishing-pressure/description-facts.md').default,
					descriptionFocus: require('./activities/fishing-pressure/description-focus.md').default,
					descriptionReasoning: require('./activities/fishing-pressure/description-reasoning.md').default,
					materials: require('./activities/fishing-pressure/materials.md').default,
					illustration: 'activity-43.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 6',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'fishing-game',
					title: 'Fiske-fangeleg',
					description: 'Kan fisken fange alle de livlige tanglopper?',
					synopsis: require('./activities/fishing-game/synopsis.md').default,
					descriptionHeader: require('./activities/fishing-game/description-header.md').default,
					descriptionTalkAbout: require('./activities/fishing-game/description-talk-about.md').default,
					descriptionFacts: require('./activities/fishing-game/description-facts.md').default,
					descriptionFocus: require('./activities/fishing-game/description-focus.md').default,
					descriptionReasoning: require('./activities/fishing-game/description-reasoning.md').default,
					materials: require('./activities/fishing-game/materials.md').default,
					illustration: 'activity-44.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 5 & 6',
					competences: [
						'Engagement og fællesskab','Krop og bevægelse'
					]
				},
				{
					id: 'sounds-from-the-past-and-present', 
					title: 'Lyde fra fortid og nutid',
					description: 'Rejse tilbage i tiden med lyde fra jægerstenalderen.',
					synopsis: require('./activities/sounds-from-the-past-and-present/synopsis.md').default,
					descriptionHeader: require('./activities/sounds-from-the-past-and-present/description-header.md').default,
					descriptionTalkAbout: require('./activities/sounds-from-the-past-and-present/description-talk-about.md').default,
					descriptionFacts: require('./activities/sounds-from-the-past-and-present/description-facts.md').default,
					descriptionFocus: require('./activities/sounds-from-the-past-and-present/description-focus.md').default,
					descriptionReasoning: require('./activities/sounds-from-the-past-and-present/description-reasoning.md').default,
					materials: require('./activities/sounds-from-the-past-and-present/materials.md').default,
					illustration: 'activity-56.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 5 & 6',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				
				
			]
		},
		{
			id: 3,
			isIndoor: false,
			isStory: true,
			activities: [
				{
					id: 'part3-1',
					title: null,
					description: null,
					synopsis: null,
					descriptionHeader: null,
					descriptionTalkAbout: null,
					descriptionFacts: null,
					descriptionFocus: null,
					descriptionReasoning: null,
					materials: null,
					illustration: null,
					storyFile: require('./activities/story/storyChapterFive.md').default,
					storyNumber: 5,
					storyTitle: 'Et skænderi',
					storyImg: 'chapter-5-thumb.png',
					storyDescription: '',
					storyAudio: 'chapter-5.mp3',
					badgeId: 'fishing',
					chapter: 'Kapitel 5',
					competences: [
						'Social', 'Dansk', 'Matematik', 'Koncentration'
					]
				},
				{
					id: 'part3-2',
					title: null,
					description: null,
					synopsis: null,
					descriptionHeader: null,
					descriptionTalkAbout: null,
					descriptionFacts: null,
					descriptionFocus: null,
					descriptionReasoning: null,
					materials: null,
					illustration: null,
					storyFile: require('./activities/story/storyChapterSix.md').default,
					storyNumber: 6,
					storyTitle: 'Fisketur',
					storyImg: 'chapter-6-thumb.png',
					storyDescription: 'At tale med ånder og vende jagtlykken.',
					storyAudio: 'chapter-6.mp3',
					badgeId: null,
					chapter: 'Kapitel 6',
					competences: [
						'Social', 'Dansk', 'Matematik', 'Koncentration'
					]
				},
			]
		},
	]},
	/* Del 4 */
	{
		id: 4,
		title: 'Del 4',
		themes: [
			{
			id: 1,
			isIndoor: true,
			badge: '',
			activities: [
				{
					id: 'tying-knots',
					title: 'Sno snor',
					description: 'Prøv at fremstille jeres egen snor af bast.',
					synopsis: require('./activities/tying-knots/synopsis.md').default,
					descriptionHeader: require('./activities/tying-knots/description-header.md').default,
					descriptionTalkAbout: require('./activities/tying-knots/description-talk-about.md').default,
					descriptionFacts: require('./activities/tying-knots/description-facts.md').default,
					descriptionFocus: require('./activities/tying-knots/description-focus.md').default,
					descriptionReasoning: require('./activities/tying-knots/description-reasoning.md').default,
					materials: require('./activities/tying-knots/materials.md').default,
					illustration: 'activity-54.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 7',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'tie-raw-ribbon-knot',
					title: 'Bind råbåndsknob',
					description: 'Lær at binde et råbåndsknob.',
					synopsis: require('./activities/tie-raw-ribbon-knot/synopsis.md').default,
					descriptionHeader: require('./activities/tie-raw-ribbon-knot/description-header.md').default,
					descriptionTalkAbout: require('./activities/tie-raw-ribbon-knot/description-talk-about.md').default,
					descriptionFacts: require('./activities/tie-raw-ribbon-knot/description-facts.md').default,
					descriptionFocus: require('./activities/tie-raw-ribbon-knot/description-focus.md').default,
					descriptionReasoning: require('./activities/tie-raw-ribbon-knot/description-reasoning.md').default,
					materials: require('./activities/tie-raw-ribbon-knot/materials.md').default,
					illustration: 'activity-01.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 7',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				
				{
					id: 'create-a-seal-colony',
					title: 'Lav en sælkoloni',
					description: 'Lav genbrugspap om til en livlig sælkoloni.',
					synopsis: require('./activities/create-a-seal-colony/synopsis.md').default,
					descriptionHeader: require('./activities/create-a-seal-colony/description-header.md').default,
					descriptionTalkAbout: require('./activities/create-a-seal-colony/description-talk-about.md').default,
					descriptionFacts: require('./activities/create-a-seal-colony/description-facts.md').default,
					descriptionFocus: require('./activities/create-a-seal-colony/description-focus.md').default,
					descriptionReasoning: require('./activities/create-a-seal-colony/description-reasoning.md').default,
					materials: require('./activities/create-a-seal-colony/materials.md').default,
					illustration: 'activity-36.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 7',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'stone-age-band-and-stomp-rhythms',
					title: 'Stenalder-band & STOMP-rytmer',
					description: 'Lav lyde og rytmer med naturmaterialer. ',
					synopsis: require('./activities/stone-age-band-and-stomp-rhythms/synopsis.md').default,
					descriptionHeader: require('./activities/stone-age-band-and-stomp-rhythms/description-header.md').default,
					descriptionTalkAbout: require('./activities/stone-age-band-and-stomp-rhythms/description-talk-about.md').default,
					descriptionFacts: require('./activities/stone-age-band-and-stomp-rhythms/description-facts.md').default,
					descriptionFocus: require('./activities/stone-age-band-and-stomp-rhythms/description-focus.md').default,
					descriptionReasoning: require('./activities/stone-age-band-and-stomp-rhythms/description-reasoning.md').default,
					materials: require('./activities/stone-age-band-and-stomp-rhythms/materials.md').default,
					illustration: 'activity-18.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 8',
					competences: [
						'Krea/musisk udtryksform','Engagement og fællesskab'
					]
				},
				
				{
					id: 'simple-drums',
					title: 'Simple trommer',
					description: 'Lav rytmer med spande og balloner.',
					synopsis: require('./activities/simple-drums/synopsis.md').default,
					descriptionHeader: require('./activities/simple-drums/description-header.md').default,
					descriptionTalkAbout: require('./activities/simple-drums/description-talk-about.md').default,
					descriptionFacts: require('./activities/simple-drums/description-facts.md').default,
					descriptionFocus: require('./activities/simple-drums/description-focus.md').default,
					descriptionReasoning: require('./activities/simple-drums/description-reasoning.md').default,
					materials: require('./activities/simple-drums/materials.md').default,
					illustration: 'activity-19.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 8',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'simple-pan-flute',
					title: 'Simpel panfløjte',
					description: 'Spil magisk musik med sugerør og tape.',
					synopsis: require('./activities/simple-pan-flute/synopsis.md').default,
					descriptionHeader: require('./activities/simple-pan-flute/description-header.md').default,
					descriptionTalkAbout: require('./activities/simple-pan-flute/description-talk-about.md').default,
					descriptionFacts: require('./activities/simple-pan-flute/description-facts.md').default,
					descriptionFocus: require('./activities/simple-pan-flute/description-focus.md').default,
					descriptionReasoning: require('./activities/simple-pan-flute/description-reasoning.md').default,
					materials: require('./activities/simple-pan-flute/materials.md').default,
					illustration: 'activity-20.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 8',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				
				{
					id: 'rattle-sticks-with-walnut-shells',
					title: 'Raslepinde med valnøddeskaller',
					description: 'Lav instrumenter af nøddeskaller og grene.',
					synopsis: require('./activities/rattle-sticks-with-walnut-shells/synopsis.md').default,
					descriptionHeader: require('./activities/rattle-sticks-with-walnut-shells/description-header.md').default,
					descriptionTalkAbout: require('./activities/rattle-sticks-with-walnut-shells/description-talk-about.md').default,
					descriptionFacts: require('./activities/rattle-sticks-with-walnut-shells/description-facts.md').default,
					descriptionFocus: require('./activities/rattle-sticks-with-walnut-shells/description-focus.md').default,
					descriptionReasoning: require('./activities/rattle-sticks-with-walnut-shells/description-reasoning.md').default,
					materials: require('./activities/rattle-sticks-with-walnut-shells/materials.md').default,
					illustration: 'activity-05.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 8',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				
				{
					id: 'clam-light',
					title: 'Musling-lys',
					description: 'Lav fine lys af muslingeskaller.',
					synopsis: require('./activities/clam-light/synopsis.md').default,
					descriptionHeader: require('./activities/clam-light/description-header.md').default,
					descriptionTalkAbout: require('./activities/clam-light/description-talk-about.md').default,
					descriptionFacts: require('./activities/clam-light/description-facts.md').default,
					descriptionFocus: require('./activities/clam-light/description-focus.md').default,
					descriptionReasoning: require('./activities/clam-light/description-reasoning.md').default,
					materials: require('./activities/clam-light/materials.md').default,
					illustration: 'activity-03.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 8',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'song-ask-and-liv',
					title: 'Ask & Liv-sangen ”På Vildspor”',
					description: 'Syng med på og bevæg jer til Ask & Liv-sangen.',
					synopsis: require('./activities/song-ask-and-liv/synopsis.md').default,
					descriptionHeader: require('./activities/song-ask-and-liv/description-header.md').default,
					descriptionTalkAbout: require('./activities/song-ask-and-liv/description-talk-about.md').default,
					descriptionFacts: require('./activities/song-ask-and-liv/description-facts.md').default,
					descriptionFocus: require('./activities/song-ask-and-liv/description-focus.md').default,
					descriptionReasoning: require('./activities/song-ask-and-liv/description-reasoning.md').default,
					materials: require('./activities/song-ask-and-liv/materials.md').default,
					illustration: 'activity-49.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 7 & 8',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'massage-history',
					title: 'Stenalder massagehistorie',
					description: 'Styrk de sociale relationer med massage.',
					synopsis: require('./activities/massage-history/synopsis.md').default,
					descriptionHeader: require('./activities/massage-history/description-header.md').default,
					descriptionTalkAbout: require('./activities/massage-history/description-talk-about.md').default,
					descriptionFacts: require('./activities/massage-history/description-facts.md').default,
					descriptionFocus: require('./activities/massage-history/description-focus.md').default,
					descriptionReasoning: require('./activities/massage-history/description-reasoning.md').default,
					materials: require('./activities/massage-history/materials.md').default,
					illustration: 'activity-33.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 7 & 8',
					competences: [
						'Engagement og fællesskab','Krop og bevægelse'
					]
				},
				
				{
					id: 'children-yoga',
					title: 'Børne-yoga i makkerpar',
					description: 'Brug yoga til at flytte fokus fra “mig” til “os”.',
					synopsis: require('./activities/children-yoga/synopsis.md').default,
					descriptionHeader: require('./activities/children-yoga/description-header.md').default,
					descriptionTalkAbout: require('./activities/children-yoga/description-talk-about.md').default,
					descriptionFacts: require('./activities/children-yoga/description-facts.md').default,
					descriptionFocus: require('./activities/children-yoga/description-focus.md').default,
					descriptionReasoning: require('./activities/children-yoga/description-reasoning.md').default,
					materials: require('./activities/children-yoga/materials.md').default,
					illustration: 'activity-09.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 7 & 8',
					competences: [
						'Krop og bevægelse','Engagement og fællesskab'
					]
				},
				{
					id: 'digital-template',
					title: 'Digital bogskabelon med opgaver',
					description: 'Arbejd med små digitale opgaver til hvert kapitel.',
					synopsis: require('./activities/digital-template/synopsis.md').default,
					descriptionHeader: require('./activities/digital-template/description-header.md').default,
					descriptionTalkAbout: require('./activities/digital-template/description-talk-about.md').default,
					descriptionFacts: require('./activities/digital-template/description-facts.md').default,
					descriptionFocus: require('./activities/digital-template/description-focus.md').default,
					descriptionReasoning: require('./activities/digital-template/description-reasoning.md').default,
					materials: require('./activities/digital-template/materials.md').default,
					illustration: 'activity-47.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 7 & 8',
					competences: [
						'Sprog', 'Naturfaglige fænomener'
					]
				},
				{
					id: 'dialog-card',
					title: 'Dialogkort',
					description: 'Tal med børnene om overgang fra børnehave til skole.',
					synopsis: require('./activities/dialog-card/synopsis.md').default,
					descriptionHeader: require('./activities/dialog-card/description-header.md').default,
					descriptionTalkAbout: require('./activities/dialog-card/description-talk-about.md').default,
					descriptionFacts: require('./activities/dialog-card/description-facts.md').default,
					descriptionFocus: require('./activities/dialog-card/description-focus.md').default,
					descriptionReasoning: require('./activities/dialog-card/description-reasoning.md').default,
					materials: require('./activities/dialog-card/materials.md').default,
					illustration: 'activity-06.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 7 & 8',
					competences: [
						'Engagement og fællesskab'
					]
				},

			]
		},
		{
			id: 2,
			isIndoor: false,
			activities: [
				{
					id: 'bow-and-arrow',
					title: 'Bue og pil',
					description: 'Lav buer og pile af naturmaterialer.',
					synopsis: require('./activities/bow-and-arrow/synopsis.md').default,
					descriptionHeader: require('./activities/bow-and-arrow/description-header.md').default,
					descriptionTalkAbout: require('./activities/bow-and-arrow/description-talk-about.md').default,
					descriptionFacts: require('./activities/bow-and-arrow/description-facts.md').default,
					descriptionFocus: require('./activities/bow-and-arrow/description-focus.md').default,
					descriptionReasoning: require('./activities/bow-and-arrow/description-reasoning.md').default,
					materials: require('./activities/bow-and-arrow/materials.md').default,
					illustration: 'activity-59.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 7',
					competences: [
						'Naturfaglige fænomener','Krea/musisk udtryksform'
					]
				},			
				{
					id: 'build-stretcher',
					title: 'Byg trækbåre',
					description: 'Byg jeres egne simple trækbårer af grene og snor.',
					synopsis: require('./activities/build-stretcher/synopsis.md').default,
					descriptionHeader: require('./activities/build-stretcher/description-header.md').default,
					descriptionTalkAbout: require('./activities/build-stretcher/description-talk-about.md').default,
					descriptionFacts: require('./activities/build-stretcher/description-facts.md').default,
					descriptionFocus: require('./activities/build-stretcher/description-focus.md').default,
					descriptionReasoning: require('./activities/build-stretcher/description-reasoning.md').default,
					materials: require('./activities/build-stretcher/materials.md').default,
					illustration: 'activity-28.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 7',
					competences: [
						'NNaturfaglige fænomener','Kreative udtryksformer'
					]
				},
				{
					id: 'stretcher-baton',
					title: 'Trækbåre-stafet',
					description: 'Brug jeres trækbårer i en sjov stafet.',
					synopsis: require('./activities/stretcher-baton/synopsis.md').default,
					descriptionHeader: require('./activities/stretcher-baton/description-header.md').default,
					descriptionTalkAbout: require('./activities/stretcher-baton/description-talk-about.md').default,
					descriptionFacts: require('./activities/stretcher-baton/description-facts.md').default,
					descriptionFocus: require('./activities/stretcher-baton/description-focus.md').default,
					descriptionReasoning: require('./activities/stretcher-baton/description-reasoning.md').default,
					materials: require('./activities/stretcher-baton/materials.md').default,
					illustration: 'activity-28.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 7',
					competences: [
						'Engagement og fællesskab','Krop og bevægelse'
					]
				},
				{
					id: 'stik-game',
					title: 'Pinde-leg',
					description: 'Styrk jeres samarbejde med en sjov pinde-leg.',
					synopsis: require('./activities/stik-game/synopsis.md').default,
					descriptionHeader: require('./activities/stik-game/description-header.md').default,
					descriptionTalkAbout: require('./activities/stik-game/description-talk-about.md').default,
					descriptionFacts: require('./activities/stik-game/description-facts.md').default,
					descriptionFocus: require('./activities/stik-game/description-focus.md').default,
					descriptionReasoning: require('./activities/stik-game/description-reasoning.md').default,
					materials: require('./activities/stik-game/materials.md').default,
					illustration: 'activity-31.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 7',
					competences: [
						'Engagement og fællesskab','Krop og bevægelse'
					]
				},
				{
					id: 'seal-hunting-game',
					title: 'Sæljagt-leg',
					description: 'Fang sælerne med en sjov boldleg.',
					synopsis: require('./activities/seal-hunting-game/synopsis.md').default,
					descriptionHeader: require('./activities/seal-hunting-game/description-header.md').default,
					descriptionTalkAbout: require('./activities/seal-hunting-game/description-talk-about.md').default,
					descriptionFacts: require('./activities/seal-hunting-game/description-facts.md').default,
					descriptionFocus: require('./activities/seal-hunting-game/description-focus.md').default,
					descriptionReasoning: require('./activities/seal-hunting-game/description-reasoning.md').default,
					materials: require('./activities/seal-hunting-game/materials.md').default,
					illustration: 'activity-36.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 7',
					competences: [
						'Engagement og fællesskab','Krop og bevægelse'
					]
				},
				{
					id: 'squirrel-game',
					title: 'Egern-leg',
					description: 'Prøv at være som det hurtige egern i tre små lege.',
					synopsis: require('./activities/squirrel-game/synopsis.md').default,
					descriptionHeader: require('./activities/squirrel-game/description-header.md').default,
					descriptionTalkAbout: require('./activities/squirrel-game/description-talk-about.md').default,
					descriptionFacts: require('./activities/squirrel-game/description-facts.md').default,
					descriptionFocus: require('./activities/squirrel-game/description-focus.md').default,
					descriptionReasoning: require('./activities/squirrel-game/description-reasoning.md').default,
					materials: require('./activities/squirrel-game/materials.md').default,
					illustration: 'activity-16.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 7',
					competences: [
						'Engagement og fællesskab','Krop og bevægelse'
					]
				},
				{
					id: 'stone-age-rhythm-sticks',
					title: 'Stenalder-rytmepinde',
					description: 'Lav simple rytmepinde af grene',
					synopsis: require('./activities/stone-age-rhythm-sticks/synopsis.md').default,
					descriptionHeader: require('./activities/stone-age-rhythm-sticks/description-header.md').default,
					descriptionTalkAbout: require('./activities/stone-age-rhythm-sticks/description-talk-about.md').default,
					descriptionFacts: require('./activities/stone-age-rhythm-sticks/description-facts.md').default,
					descriptionFocus: require('./activities/stone-age-rhythm-sticks/description-focus.md').default,
					descriptionReasoning: require('./activities/stone-age-rhythm-sticks/description-reasoning.md').default,
					materials: require('./activities/stone-age-rhythm-sticks/materials.md').default,
					illustration: 'activity-22.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 8',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'shamans-staff',
					title: 'Magiske Shamanstave',
					description: 'Lav jeres egne magiske shamanstave.',
					synopsis: require('./activities/stone-age-rhythm-sticks/synopsis.md').default,
					descriptionHeader: require('./activities/stone-age-rhythm-sticks/description-header.md').default,
					descriptionTalkAbout: require('./activities/stone-age-rhythm-sticks/description-talk-about.md').default,
					descriptionFacts: require('./activities/stone-age-rhythm-sticks/description-facts.md').default,
					descriptionFocus: require('./activities/stone-age-rhythm-sticks/description-focus.md').default,
					descriptionReasoning: require('./activities/stone-age-rhythm-sticks/description-reasoning.md').default,
					materials: require('./activities/stone-age-rhythm-sticks/materials.md').default,
					illustration: 'activity-21.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 8',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				
				{
					id: 'cut-a-buzzer',
					title: 'Snit en brummer',
					description: 'Skab magiske lyde fra fortiden med træ og snor.',
					synopsis: require('./activities/cut-a-buzzer/synopsis.md').default,
					descriptionHeader: require('./activities/cut-a-buzzer/description-header.md').default,
					descriptionTalkAbout: require('./activities/cut-a-buzzer/description-talk-about.md').default,
					descriptionFacts: require('./activities/cut-a-buzzer/description-facts.md').default,
					descriptionFocus: require('./activities/cut-a-buzzer/description-focus.md').default,
					descriptionReasoning: require('./activities/cut-a-buzzer/description-reasoning.md').default,
					materials: require('./activities/cut-a-buzzer/materials.md').default,
					illustration: 'activity-72.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 8',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'beach-hunting',
					title: 'Strandjagt i vandkanten',
					description: 'Gå på jagt efter alt det, bølgerne har skyllet op.',
					synopsis: require('./activities/beach-hunting/synopsis.md').default,
					descriptionHeader: require('./activities/beach-hunting/description-header.md').default,
					descriptionTalkAbout: require('./activities/beach-hunting/description-talk-about.md').default,
					descriptionFacts: require('./activities/beach-hunting/description-facts.md').default,
					descriptionFocus: require('./activities/beach-hunting/description-focus.md').default,
					descriptionReasoning: require('./activities/beach-hunting/description-reasoning.md').default,
					materials: require('./activities/beach-hunting/materials.md').default,
					illustration: 'activity-23.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 8',
					competences: [
						'Naturfaglige fænomener','Engagement og fællesskab'
					]
				},
				{
					id: 'sounds-from-the-past-and-present',
					title: 'Lyde fra fortid og nutid',
					description: 'Rejse tilbage i tiden med lyde fra jægerstenalderen.',
					synopsis: require('./activities/sounds-from-the-past-and-present/synopsis.md').default,
					descriptionHeader: require('./activities/sounds-from-the-past-and-present/description-header.md').default,
					descriptionTalkAbout: require('./activities/sounds-from-the-past-and-present/description-talk-about.md').default,
					descriptionFacts: require('./activities/sounds-from-the-past-and-present/description-facts.md').default,
					descriptionFocus: require('./activities/sounds-from-the-past-and-present/description-focus.md').default,
					descriptionReasoning: require('./activities/sounds-from-the-past-and-present/description-reasoning.md').default,
					materials: require('./activities/sounds-from-the-past-and-present/materials.md').default,
					illustration: 'activity-56.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 7 & 8',
					competences: [
						'Naturfaglige fænomener'
					]
				},
			]
		},
		{
			id: 3,
			isIndoor: false,
			activities: [
				{
					id: 'part4-1',
					title: null,
					description: null,
					synopsis: null,
					descriptionHeader: null,
					descriptionTalkAbout: null,
					descriptionFacts: null,
					descriptionFocus: null,
					descriptionReasoning: null,
					materials: null,
					illustration: null,
					storyFile: require('./activities/story/storyChapterSeven.md').default,
					storyNumber: 7,
					storyTitle: 'Sæljagt',
					storyImg: 'chapter-7-thumb.png',
					storyDescription: '',
					storyAudio: 'chapter-7.mp3',
					badgeId: 'stretcher',
					chapter: 'Kapitel 7',
					competences: [
						'Social', 'Dansk', 'Matematik', 'Koncentration'
					]
				},
				{
					id: 'part4-2',
					title: null,
					description: null,
					synopsis: null,
					descriptionHeader: null,
					descriptionTalkAbout: null,
					descriptionFacts: null,
					descriptionFocus: null,
					descriptionReasoning: null,
					materials: null,
					illustration: null,
					storyFile: require('./activities/story/storyChapterEight.md').default,
					storyNumber: 8,
					storyTitle: 'De voksne',
					storyImg: 'chapter-8-thumb.png',
					storyDescription: 'At tale med ånder og vende jagtlykken.',
					storyAudio: 'chapter-8.mp3',
					badgeId: null,
					chapter: 'Kapitel 8',
					competences: [
						'Social', 'Dansk', 'Matematik', 'Koncentration'
					]
				},
			]
		},
	]},
	/* Del 5 */
	{
		id: 3,
		title: 'Del 5',
		themes: [
			{
			id: 1,
			isIndoor: true,
			badge: '',
			activities: [
				{
					id: 'bonfire-in-a-boat',
					title: 'Bål i båd',
					description: 'Lav flotte billeder af stammebåde med ild ombord.',
					synopsis: require('./activities/bonfire-in-a-boat/synopsis.md').default,
					descriptionHeader: require('./activities/bonfire-in-a-boat/description-header.md').default,
					descriptionTalkAbout: require('./activities/bonfire-in-a-boat/description-talk-about.md').default,
					descriptionFacts: require('./activities/bonfire-in-a-boat/description-facts.md').default,
					descriptionFocus: require('./activities/bonfire-in-a-boat/description-focus.md').default,
					descriptionReasoning: require('./activities/bonfire-in-a-boat/description-reasoning.md').default,
					materials: require('./activities/bonfire-in-a-boat/materials.md').default,
					illustration: 'activity-53.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 9',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'my-offering',
					title: 'Min offergave',
					description: 'Tegn en offergave af noget, som har stor betydning for jer.',
					synopsis: require('./activities/my-offering/synopsis.md').default,
					descriptionHeader: require('./activities/my-offering/description-header.md').default,
					descriptionTalkAbout: require('./activities/my-offering/description-talk-about.md').default,
					descriptionFacts: require('./activities/my-offering/description-facts.md').default,
					descriptionFocus: require('./activities/my-offering/description-focus.md').default,
					descriptionReasoning: require('./activities/my-offering/description-reasoning.md').default,
					materials: require('./activities/my-offering/materials.md').default,
					illustration: 'activity-13.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 10',
					competences: [	
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'cave-painting',
					title: 'Hulemaleri',
					description: 'Lav unikke hulemalerier med dyr og håndaftryk.',
					synopsis: require('./activities/cave-painting/synopsis.md').default,
					descriptionHeader: require('./activities/cave-painting/description-header.md').default,
					descriptionTalkAbout: require('./activities/cave-painting/description-talk-about.md').default,
					descriptionFacts: require('./activities/cave-painting/description-facts.md').default,
					descriptionFocus: require('./activities/cave-painting/description-focus.md').default,
					descriptionReasoning: require('./activities/cave-painting/description-reasoning.md').default,
					materials: require('./activities/cave-painting/materials.md').default,
					illustration: 'activity-50.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 10',
					competences: [	
						'Krea/musisk udtryksform','Engagement og fællesskab'
					]
				},
				{
					id: 'stone-age-stomp-and-rhyme-seal-call',
					title: 'Stenalder STOMP & Rim - Sælkald',
					description: 'Lav STOMP og kald på sælånderne.',
					synopsis: require('./activities/stone-age-stomp-and-rhyme-seal-call/synopsis.md').default,
					descriptionHeader: require('./activities/stone-age-stomp-and-rhyme-seal-call/description-header.md').default,
					descriptionTalkAbout: require('./activities/stone-age-stomp-and-rhyme-seal-call/description-talk-about.md').default,
					descriptionFacts: require('./activities/stone-age-stomp-and-rhyme-seal-call/description-facts.md').default,
					descriptionFocus: require('./activities/stone-age-stomp-and-rhyme-seal-call/description-focus.md').default,
					descriptionReasoning: require('./activities/stone-age-stomp-and-rhyme-seal-call/description-reasoning.md').default,
					materials: require('./activities/stone-age-stomp-and-rhyme-seal-call/materials.md').default,
					illustration: 'activity-46.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 9 & 10',
					competences: [	
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'stone-age-stomp-and-rhyme-tribal-party',
					title: 'Stenalder STOMP & Rim - Stammefest',
					description: 'Lav festlige rytmer til jeres stammefest!',
					synopsis: require('./activities/stone-age-stomp-and-rhyme-tribal-party/synopsis.md').default,
					descriptionHeader: require('./activities/stone-age-stomp-and-rhyme-tribal-party/description-header.md').default,
					descriptionTalkAbout: require('./activities/stone-age-stomp-and-rhyme-tribal-party/description-talk-about.md').default,
					descriptionFacts: require('./activities/stone-age-stomp-and-rhyme-tribal-party/description-facts.md').default,
					descriptionFocus: require('./activities/stone-age-stomp-and-rhyme-tribal-party/description-focus.md').default,
					descriptionReasoning: require('./activities/stone-age-stomp-and-rhyme-tribal-party/description-reasoning.md').default,
					materials: require('./activities/stone-age-stomp-and-rhyme-tribal-party/materials.md').default,
					illustration: 'activity-69.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 9 & 10',
					competences: [	
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'song-ask-and-liv',
					title: 'Ask & Liv-sangen ”På Vildspor”',
					description: 'Syng med på og bevæg jer til Ask & Liv-sangen.',
					synopsis: require('./activities/song-ask-and-liv/synopsis.md').default,
					descriptionHeader: require('./activities/song-ask-and-liv/description-header.md').default,
					descriptionTalkAbout: require('./activities/song-ask-and-liv/description-talk-about.md').default,
					descriptionFacts: require('./activities/song-ask-and-liv/description-facts.md').default,
					descriptionFocus: require('./activities/song-ask-and-liv/description-focus.md').default,
					descriptionReasoning: require('./activities/song-ask-and-liv/description-reasoning.md').default,
					materials: require('./activities/song-ask-and-liv/materials.md').default,
					illustration: 'activity-49.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 9 & 10',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'massage-history',
					title: 'Stenalder massagehistorie',
					description: 'Styrk de sociale relationer med massage.',
					synopsis: require('./activities/massage-history/synopsis.md').default,
					descriptionHeader: require('./activities/massage-history/description-header.md').default,
					descriptionTalkAbout: require('./activities/massage-history/description-talk-about.md').default,
					descriptionFacts: require('./activities/massage-history/description-facts.md').default,
					descriptionFocus: require('./activities/massage-history/description-focus.md').default,
					descriptionReasoning: require('./activities/massage-history/description-reasoning.md').default,
					materials: require('./activities/massage-history/materials.md').default,
					illustration: 'activity-33.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 9 & 10',
					competences: [
						'Engagement og fællesskab','Krop og bevægelse'
					]
				},
				
				{
					id: 'children-yoga',
					title: 'Børne-yoga i makkerpar',
					description: 'Brug yoga til at flytte fokus fra “mig” til “os”.',
					synopsis: require('./activities/children-yoga/synopsis.md').default,
					descriptionHeader: require('./activities/children-yoga/description-header.md').default,
					descriptionTalkAbout: require('./activities/children-yoga/description-talk-about.md').default,
					descriptionFacts: require('./activities/children-yoga/description-facts.md').default,
					descriptionFocus: require('./activities/children-yoga/description-focus.md').default,
					descriptionReasoning: require('./activities/children-yoga/description-reasoning.md').default,
					materials: require('./activities/children-yoga/materials.md').default,
					illustration: 'activity-09.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 9 & 10',
					competences: [
						'Krop og bevægelse','Engagement og fællesskab'
					]
				},
				{
					id: 'digital-template',
					title: 'Digital bogskabelon med opgaver',
					description: 'Arbejd med små digitale opgaver til hvert kapitel.',
					synopsis: require('./activities/digital-template/synopsis.md').default,
					descriptionHeader: require('./activities/digital-template/description-header.md').default,
					descriptionTalkAbout: require('./activities/digital-template/description-talk-about.md').default,
					descriptionFacts: require('./activities/digital-template/description-facts.md').default,
					descriptionFocus: require('./activities/digital-template/description-focus.md').default,
					descriptionReasoning: require('./activities/digital-template/description-reasoning.md').default,
					materials: require('./activities/digital-template/materials.md').default,
					illustration: 'activity-47.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 9 & 10',
					competences: [
						'Sprog', 'Naturfaglige fænomener'
					]
				},
				{
					id: 'dialog-card',
					title: 'Dialogkort',
					description: 'Tal med børnene om overgang fra børnehave til skole.',
					synopsis: require('./activities/dialog-card/synopsis.md').default,
					descriptionHeader: require('./activities/dialog-card/description-header.md').default,
					descriptionTalkAbout: require('./activities/dialog-card/description-talk-about.md').default,
					descriptionFacts: require('./activities/dialog-card/description-facts.md').default,
					descriptionFocus: require('./activities/dialog-card/description-focus.md').default,
					descriptionReasoning: require('./activities/dialog-card/description-reasoning.md').default,
					materials: require('./activities/dialog-card/materials.md').default,
					illustration: 'activity-06.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 9 & 10',
					competences: [
						'Engagement og fællesskab'
					]
				},
			]
		},
		{
			id: 2,
			isIndoor: false,
			activities: [
				{
					id: 'mini-tribal-boats',
					title: 'Mini stammebåde',
					description: 'Lav små, seje både af udhulet hyld.',
					synopsis: require('./activities/mini-tribal-boats/synopsis.md').default,
					descriptionHeader: require('./activities/mini-tribal-boats/description-header.md').default,
					descriptionTalkAbout: require('./activities/mini-tribal-boats/description-talk-about.md').default,
					descriptionFacts: require('./activities/mini-tribal-boats/description-facts.md').default,
					descriptionFocus: require('./activities/mini-tribal-boats/description-focus.md').default,
					descriptionReasoning: require('./activities/mini-tribal-boats/description-reasoning.md').default,
					materials: require('./activities/mini-tribal-boats/materials.md').default,
					illustration: 'activity-48.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 9',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				{
					id: 'simple-mini-boats',
					title: 'Simple minibåde',
					description: 'Lav små både af naturmaterialer.',
					synopsis: require('./activities/simple-mini-boats/synopsis.md').default,
					descriptionHeader: require('./activities/simple-mini-boats/description-header.md').default,
					descriptionTalkAbout: require('./activities/simple-mini-boats/description-talk-about.md').default,
					descriptionFacts: require('./activities/simple-mini-boats/description-facts.md').default,
					descriptionFocus: require('./activities/simple-mini-boats/description-focus.md').default,
					descriptionReasoning: require('./activities/simple-mini-boats/description-reasoning.md').default,
					materials: require('./activities/simple-mini-boats/materials.md').default,
					illustration: 'activity-24.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 9',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				{
					id: 'christen-mini-boats',
					title: 'Søsæt minibåde',
					description: 'Sæt jeres minibåde i søen, og se om de flyder eller synker.',
					synopsis: require('./activities/christen-mini-boats/synopsis.md').default,
					descriptionHeader: require('./activities/christen-mini-boats/description-header.md').default,
					descriptionTalkAbout: require('./activities/christen-mini-boats/description-talk-about.md').default,
					descriptionFacts: require('./activities/christen-mini-boats/description-facts.md').default,
					descriptionFocus: require('./activities/christen-mini-boats/description-focus.md').default,
					descriptionReasoning: require('./activities/christen-mini-boats/description-reasoning.md').default,
					materials: require('./activities/christen-mini-boats/materials.md').default,
					illustration: 'activity-24.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 9',
					competences: [
						'Naturfaglige fænomener','Engagement og fællesskab'
					]
				},
				{
					id: 'beach-hunting',
					title: 'Strandjagt i vandkanten',
					description: 'Gå på jagt efter alt det, bølgerne har skyllet op. ',
					synopsis: require('./activities/beach-hunting/synopsis.md').default,
					descriptionHeader: require('./activities/beach-hunting/description-header.md').default,
					descriptionTalkAbout: require('./activities/beach-hunting/description-talk-about.md').default,
					descriptionFacts: require('./activities/beach-hunting/description-facts.md').default,
					descriptionFocus: require('./activities/beach-hunting/description-focus.md').default,
					descriptionReasoning: require('./activities/beach-hunting/description-reasoning.md').default,
					materials: require('./activities/beach-hunting/materials.md').default,
					illustration: 'activity-23.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 9',
					competences: [
						'Naturfaglige fænomener','Engagement og fællesskab'
					]
				},
				{
					id: 'sank-clams-and-oysters',
					title: 'Sank muslinger og østers',
					description: 'Tag ud til vandet og find lækre østers og muslinger.',
					synopsis: require('./activities/sank-clams-and-oysters/synopsis.md').default,
					descriptionHeader: require('./activities/sank-clams-and-oysters/description-header.md').default,
					descriptionTalkAbout: require('./activities/sank-clams-and-oysters/description-talk-about.md').default,
					descriptionFacts: require('./activities/sank-clams-and-oysters/description-facts.md').default,
					descriptionFocus: require('./activities/sank-clams-and-oysters/description-focus.md').default,
					descriptionReasoning: require('./activities/sank-clams-and-oysters/description-reasoning.md').default,
					materials: require('./activities/sank-clams-and-oysters/materials.md').default,
					illustration: 'activity-12.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 9',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				{
					id: 'a-taste-of-the-stone-age',
					title: 'Smag på jægerstenalderen',
					description: 'Lav mad over bål som i jægerstenalderen.',
					synopsis: require('./activities/a-taste-of-the-stone-age/synopsis.md').default,
					descriptionHeader: require('./activities/a-taste-of-the-stone-age/description-header.md').default,
					descriptionTalkAbout: require('./activities/a-taste-of-the-stone-age/description-talk-about.md').default,
					descriptionFacts: require('./activities/a-taste-of-the-stone-age/description-facts.md').default,
					descriptionFocus: require('./activities/a-taste-of-the-stone-age/description-focus.md').default,
					descriptionReasoning: require('./activities/a-taste-of-the-stone-age/description-reasoning.md').default,
					materials: require('./activities/a-taste-of-the-stone-age/materials.md').default,
					illustration: 'activity-71.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 9',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				{
					id: 'sacrifice-of-oblations',
					title: 'Ofring af offergaver',
					description: 'Hold en lille ofringsceremoni ligesom Ask, Liv og Pil.',
					synopsis: require('./activities/sacrifice-of-oblations/synopsis.md').default,
					descriptionHeader: require('./activities/sacrifice-of-oblations/description-header.md').default,
					descriptionTalkAbout: require('./activities/sacrifice-of-oblations/description-talk-about.md').default,
					descriptionFacts: require('./activities/sacrifice-of-oblations/description-facts.md').default,
					descriptionFocus: require('./activities/sacrifice-of-oblations/description-focus.md').default,
					descriptionReasoning: require('./activities/sacrifice-of-oblations/description-reasoning.md').default,
					materials: require('./activities/sacrifice-of-oblations/materials.md').default,
					illustration: 'activity-15.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 10',
					competences: [
						'Engagement og fællesskab'
					]
				},
				{
					id: 'make-charcoal',
					title: 'Lav tegnekul',
					description: 'Lav grene om til hjemmelavede tegnekul.',
					synopsis: require('./activities/make-charcoal/synopsis.md').default,
					descriptionHeader: require('./activities/make-charcoal/description-header.md').default,
					descriptionTalkAbout: require('./activities/make-charcoal/description-talk-about.md').default,
					descriptionFacts: require('./activities/make-charcoal/description-facts.md').default,
					descriptionFocus: require('./activities/make-charcoal/description-focus.md').default,
					descriptionReasoning: require('./activities/make-charcoal/description-reasoning.md').default,
					materials: require('./activities/make-charcoal/materials.md').default,
					illustration: 'activity-68.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 10',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'natural-paint',
					title: 'Natur-maling',
					description: 'Fremstil jeres egen maling af naturlige materialer.',
					synopsis: require('./activities/natural-paint/synopsis.md').default,
					descriptionHeader: require('./activities/natural-paint/description-header.md').default,
					descriptionTalkAbout: require('./activities/natural-paint/description-talk-about.md').default,
					descriptionFacts: require('./activities/natural-paint/description-facts.md').default,
					descriptionFocus: require('./activities/natural-paint/description-focus.md').default,
					descriptionReasoning: require('./activities/natural-paint/description-reasoning.md').default,
					materials: require('./activities/natural-paint/materials.md').default,
					illustration: 'activity-14.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 10',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'natural-brushes',
					title: 'Natur-pensler',
					description: 'Lav jeres egne pensler af naturmaterialer.',
					synopsis: require('./activities/natural-brushes/synopsis.md').default,
					descriptionHeader: require('./activities/natural-brushes/description-header.md').default,
					descriptionTalkAbout: require('./activities/natural-brushes/description-talk-about.md').default,
					descriptionFacts: require('./activities/natural-brushes/description-facts.md').default,
					descriptionFocus: require('./activities/natural-brushes/description-focus.md').default,
					descriptionReasoning: require('./activities/natural-brushes/description-reasoning.md').default,
					materials: require('./activities/natural-brushes/materials.md').default,
					illustration: 'activity-11.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 10',
					competences: [
						'Krea/musisk udtryksform'
					]
				},
				{
					id: 'party-at-the-residence',
					title: 'Fest på bopladsen',
					description: 'Afslut forløbet med et brag.',
					synopsis: require('./activities/party-at-the-residence/synopsis.md').default,
					descriptionHeader: require('./activities/party-at-the-residence/description-header.md').default,
					descriptionTalkAbout: require('./activities/party-at-the-residence/description-talk-about.md').default,
					descriptionFacts: require('./activities/party-at-the-residence/description-facts.md').default,
					descriptionFocus: require('./activities/party-at-the-residence/description-focus.md').default,
					descriptionReasoning: require('./activities/party-at-the-residence/description-reasoning.md').default,
					materials: require('./activities/party-at-the-residence/materials.md').default,
					illustration: 'activity-46.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 10',
					competences: [
						'Engagement og fællesskab','Krop og bevægelse'
					]
				},
				{
					id: 'visit-by-bnv', 
					title: 'Besøg af en jæger/fisker',
					description: 'Bliv klogere på natur med en fisker eller jæger.',
					synopsis: require('./activities/visit-by-bnv/synopsis.md').default,
					descriptionHeader: require('./activities/visit-by-bnv/description-header.md').default,
					descriptionTalkAbout: require('./activities/visit-by-bnv/description-talk-about.md').default,
					descriptionFacts: require('./activities/visit-by-bnv/description-facts.md').default,
					descriptionFocus: require('./activities/visit-by-bnv/description-focus.md').default,
					descriptionReasoning: require('./activities/visit-by-bnv/description-reasoning.md').default,
					materials: require('./activities/visit-by-bnv/materials.md').default,
					illustration: 'activity-66.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 10',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				{
					id: 'a-taste-of-the-stone-age',
					title: 'Smag på jægerstenalderen',
					description: 'Lav mad over bål som i jægerstenalderen.',
					synopsis: require('./activities/a-taste-of-the-stone-age/synopsis.md').default,
					descriptionHeader: require('./activities/a-taste-of-the-stone-age/description-header.md').default,
					descriptionTalkAbout: require('./activities/a-taste-of-the-stone-age/description-talk-about.md').default,
					descriptionFacts: require('./activities/a-taste-of-the-stone-age/description-facts.md').default,
					descriptionFocus: require('./activities/a-taste-of-the-stone-age/description-focus.md').default,
					descriptionReasoning: require('./activities/a-taste-of-the-stone-age/description-reasoning.md').default,
					materials: require('./activities/a-taste-of-the-stone-age/materials.md').default,
					illustration: 'activity-71.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 10',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				{
					id: 'stone-age-band',
					title: 'Stenalder-band',
					description: 'Lav jeres eget stenalder-band!',
					synopsis: require('./activities/stone-age-band/synopsis.md').default,
					descriptionHeader: require('./activities/stone-age-band/description-header.md').default,
					descriptionTalkAbout: require('./activities/stone-age-band/description-talk-about.md').default,
					descriptionFacts: require('./activities/stone-age-band/description-facts.md').default,
					descriptionFocus: require('./activities/stone-age-band/description-focus.md').default,
					descriptionReasoning: require('./activities/stone-age-band/description-reasoning.md').default,
					materials: require('./activities/stone-age-band/materials.md').default,
					illustration: 'activity-18.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 9 & 10',
					competences: [
						'Krea/musisk udtryksform','Engagement og fællesskab'
					]
				},
				// {
				// 	id: 'stone-age-stomp-and-rhyme',
				// 	title: 'Stenalder STOMP & Rim (ark)',
				// 	description: 'Lav smagsprøver på stenaldermad.',
				// 	synopsis: require('./activities/stone-age-stomp-and-rhyme/synopsis.md').default,
				// 	descriptionHeader: require('./activities/stone-age-stomp-and-rhyme/description-header.md').default,
				// 	descriptionTalkAbout: require('./activities/stone-age-stomp-and-rhyme/description-talk-about.md').default,
				// 	descriptionFacts: require('./activities/stone-age-stomp-and-rhyme/description-facts.md').default,
				// 	descriptionFocus: require('./activities/stone-age-stomp-and-rhyme/description-focus.md').default,
				// 	descriptionReasoning: require('./activities/stone-age-stomp-and-rhyme/description-reasoning.md').default,
				// 	materials: require('./activities/stone-age-stomp-and-rhyme/materials.md').default,
				// 	illustration: 'activity-71.png',
				// 	storyFile: null,
				// 	storyNumber: null,
				// 	storyTitle: null,
				// 	storyDescription: null,
				// 	storyAudio: null,
				// 	badgeId: null,
				// 	chapter: 'Kapitel 9 & 10',
				// 	competences: [
				// 		'Krea/musisk udtryksform','Engagement og fællesskab'
				// 	]
				// },
				{
					id: 'sounds-from-the-past-and-present',
					title: 'Lyde fra fortid og nutid',
					description: 'Rejse tilbage i tiden med lyde fra jægerstenalderen.',
					synopsis: require('./activities/sounds-from-the-past-and-present/synopsis.md').default,
					descriptionHeader: require('./activities/sounds-from-the-past-and-present/description-header.md').default,
					descriptionTalkAbout: require('./activities/sounds-from-the-past-and-present/description-talk-about.md').default,
					descriptionFacts: require('./activities/sounds-from-the-past-and-present/description-facts.md').default,
					descriptionFocus: require('./activities/sounds-from-the-past-and-present/description-focus.md').default,
					descriptionReasoning: require('./activities/sounds-from-the-past-and-present/description-reasoning.md').default,
					materials: require('./activities/sounds-from-the-past-and-present/materials.md').default,
					illustration: 'activity-56.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null,
					chapter: 'Kapitel 9 & 10',
					competences: [
						'Naturfaglige fænomener'
					]
				},
				
			]
		},
		{
			id: 3,
			isIndoor: false,
			isStory: true,
			activities: [
				{
					id: 'part5-1',
					title: null,
					description: null,
					synopsis: null,
					descriptionHeader: null,
					descriptionTalkAbout: null,
					descriptionFacts: null,
					descriptionFocus: null,
					descriptionReasoning: null,
					materials: null,
					illustration: null,
					storyFile: require('./activities/story/storyChapterNine.md').default,
					storyNumber: 9,
					storyTitle: 'Ud på vandet',
					storyImg: 'chapter-9-thumb.png',
					storyDescription: '',
					storyAudio: 'chapter-9.mp3',
					badgeId: 'music',
					chapter: 'Kapitel 9',
					competences: [
						'Social', 'Dansk', 'Matematik', 'Koncentration'
					]
				},
				{
					id: 'part5-2',
					title: null,
					description: null,
					synopsis: null,
					descriptionHeader: null,
					descriptionTalkAbout: null,
					descriptionFacts: null,
					descriptionFocus: null,
					descriptionReasoning: null,
					materials: null,
					illustration: null,
					storyFile: require('./activities/story/storyChapterTen.md').default,
					storyNumber: 10,
					storyTitle: 'Ånderne',
					storyImg: 'chapter-10-thumb.png',
					storyDescription: 'At tale med ånder og vende jagtlykken.',
					storyAudio: 'chapter-10.mp3',
					badgeId: null,
					chapter: 'Kapitel 10',
					competences: [
						'Social', 'Dansk', 'Matematik', 'Koncentration'
					]
				},
			]
		},
	]}
];



export default chapterData;