import React, { useState, useEffect } from 'react';
import './chapter-six.scss';

const chapterSix = () => {


		
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [animateBird, setAnimateBird] = useState(true);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		// random values betwee 1000-5000
		const interval = setInterval(() => {setAnimateBird(!animateBird);}, 
			(Math.floor(Math.random() * 8) + 10) * 1000);

		return () => {
			clearInterval(interval);
		};
	}, [animateBird]);
	return (
		<div className="chapterSix">
			<div className='chapterSix-background'/>
			{/* <div className='chapterSix-clouds'/> */}

			<div className={'chapterSix-birds ' + (animateBird ? 'chapterSix-birds--animate' : '')}/>

			<div className='chapterSix-water'/>
			<div className='chapterSix-fog chapterSix-fog--1'/>
			<div className='chapterSix-foreground'/>
		</div>
	);
};


export default chapterSix;