import React, { useState, useEffect } from 'react';
import './chapter-eight.scss';

const chapterEight = () => {

	
	
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [animateBird, setAnimateBird] = useState(true);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		// random values betwee 1000-5000
		const interval = setInterval(() => {setAnimateBird(!animateBird);}, 
			(Math.floor(Math.random() * 5) + 6) * 1000 );

		return () => {
			clearInterval(interval);
		};
	}, [animateBird]);


	return (
		<div className="chapterEight"> 
			<div className='chapterEight-background'/>
			<div className='chapterEight-fogContainer'> 
				<div className='chapterEight-fog'/> 
			</div>
			<div className={'chapterEight-birds ' + (animateBird ? 'chapterEight-birds--animate' : '')}/>
			<div className='chapterEight-foreground'/>
			<div className='chapterEight-glow chapterEight-glow--1'/>
			<div className='chapterEight-glow chapterEight-glow--2'/>
			<div className='chapterEight-glow chapterEight-glow--3'/>
		</div>
	);
};

export default chapterEight;