const badgesData = [
	{
		id: 'hunt',
		name: 'Jagt'
	},
	{
		id: 'fire',
		name: 'Bål'
	},
	{
		id: 'fishing',
		name: 'Fiskeri'
	},
	{
		id: 'stretcher',
		name: 'Trækbåre'
	},
	{
		id: 'music',
		name: 'Musik'
	},
];

export default badgesData;